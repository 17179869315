import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import '../../assets/css/menu.css';
import ima from '../../../src/assets/images/impacto-zero/logos_iconos/Ies_logo_ImpactoZ.png';
import { Auth } from "../../utils/AuthContext";
import { MenuOutlined } from '@ant-design/icons';


import Login from '../registro/Login';

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
    duration: 10,
  });
}

const secciones = [
  //{ nombre: 'INICIO', nombre_en: 'HOME', url: '/' },
  //{ nombre: 'ACERCA DE', nombre_en: 'ABOUT US', url: '/#acerca-de' },
  //{ nombre: 'AGENDA', nombre_en: 'PROGRAM', url: '/#agenda' },
  //{ nombre: 'CONFERENCISTAS', nombre_en: 'SPEAKERS', url: '/#conferencistas' },
  //{ nombre: 'PANELES', nombre_en: 'PANELS', url: '/#panel',tipo:'' },//clase
  //{ nombre: 'PATROCINADORES', nombre_en: 'SPONSORS', url: '/patrocinadores',tipo:'' },//clase
  //{nombre:'Sala de conferencias', url:'/en-vivo'},
  //{nombre:'Expo', url:'/expo'},
  //{nombre:'Mi cuenta', url:'/cuenta'}
]


const Header = (props) => {

  //const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_menu_movil, setMostrarMenuMovil] = useState(false);
  const { usuario } = useContext(Auth);
  //const { height, width, ref } = useResizeDetector();
  const [mostrar_menu, setMostrarMenu] = useState(true);
  const { setIdioma, idioma, mostrar_login, setMostrarLogin} = props;

  //setIdioma('es'||'en')

  let mensajelogin = idioma === 'es' ? 'Por favor inicie sesión para acceder este contenido.' : 'Please login to access this content';
  useEffect(()=>{
    if(!usuario){
      //setMostrarLogin(true);
    }
  },[]);

  const [scrollY, setScrollY] = useState(0);
  const [scrol, setScrol] = useState(150);

  function logit() {
    setScrollY(window.pageYOffset);
    setScrol(scrollY);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  /*useEffect(() => {
    if (width > 767) {
      setMostrarMenuMovil(true);
    } else {
      setMostrarMenuMovil(false);
    }
  }, [width]);*/

  const cerrarMenu = () => {
      setMostrarMenuMovil(false);
  }

  const success = () => {
    mensaje('success', 'Bienvenido', '')
  }

  let visualizar_secciones = secciones.map((seccion) => {
    if(seccion.tipo === 'clase'){
      return (
        <Link className="titles top-and-bottom" to={seccion.url} onClick={cerrarMenu}>
          {idioma === 'es' ?
            <span className="seccion" >
              {seccion.nombre}
            </span>
            :
            <span className="seccion" >
              {seccion.nombre_en}
            </span>
          }
        </Link>
      );
    }else{
      return (
        <Link className="titles" to={seccion.url} onClick={cerrarMenu}>
          {idioma === 'es' ?
            <span className="seccion" >
              {seccion.nombre}
            </span>
            :
            <span className="seccion" >
              {seccion.nombre_en}
            </span>
          }
        </Link>
      );
    }
  });

  return (
    <Row justify="center" align="middle" className={"contenedor_header"}> {/*(scrol <= scrollY && scrollY > 80) ? "navbar--hidden" : "contenedor_header" */}
      <Col xs={10} md={8} style={{ padding: '10px', textAlign: 'left' }}>
        <img alt="logo" className="log_header-" src={ima} style={{width:'100%'}} />
      </Col>
      <Col xs={{span:0, offset:0}} md={{span:4, offset:0}} style={{display:'none'}}>
        <p className="sin_margin">
          <span className={idioma === 'es' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('es')}>ESPAÑOL</span> 
          <span className="idioma_no"> | </span> 
          <span className={idioma === 'en' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('en')}>ENGLISH</span>
        </p>
      </Col>
      <Col xs={{span:8, offset:5}} md={{span:0, offset:0}}  style={{display:'none'}}>
        <p className="sin_margin">
          <span className={idioma === 'es' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('es')}>ES</span> 
          <span className="idioma_no"> | </span> 
          <span className={idioma === 'en' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('en')}>EN</span>
        </p>
      </Col>
      <Col xs={{ span: 4, offset: 4 }} md={{ span: 0, offset: 0 }} style={{display:'none'}}>
        <MenuOutlined style={{ color: '#ffffff' }} onClick={() => setMostrarMenuMovil(!mostrar_menu_movil)} />
      </Col>
      <Col xs={{ span: 0, offset: 0 }} md={{ span: 13, offset: 0 }}  style={{display:'none'}}>
          <>

            {visualizar_secciones}
            {usuario ?
              <>
                <Link className="titles" to="/on-demand" onClick={cerrarMenu} style={{display:'none'}}>
                  {idioma === 'es' ?
                    <span className="seccion ondemand_btn" >
                      On demand
        </span>
                    :
                    <span className="seccion ondemand_btn" >
                      On demand
          </span>
                  }
                </Link>

              </>
              :
              <>
                {<Link className="titles" to="#" onClick={()=>{mensaje('warning',mensajelogin); setMostrarLogin(true); cerrarMenu();}} style={{display:'none'}}>
          <span className="seccion ondemand_btn" >
            On demand
          </span>
        </Link>}

              </>
            }

           
              <>
                {usuario ?
                  <Link className="titles menu_mostrar_movil" to="/cerrar-sesion" onClick={() => { setMostrarLogin(true); cerrarMenu(); }}>
                    {idioma === 'es' ?
                      <span className="seccion" >
                        Cerrar sesión
        </span>
                      :
                      <span className="seccion" >
                        Log out
          </span>
                    }
                  </Link>
                  :

                  <a className="titles registro_btn menu_mostrar_movil" href="registro" style={{display:'none'}}>
                    {idioma === 'es' ?
                      <span className="seccion" >
                        REGISTRO
        </span>
                      :
                      <span className="seccion" >
                        SIGN UP
          </span>
                    }
                  </a>
                }
              </>
              

          </>

        

      </Col>
      
      <Col xs={{ span: mostrar_menu_movil ? 24 : 0, offset: 0 }} md={{ span: 0, offset: 0 }}>
          <>

            {visualizar_secciones}
            {usuario ?
              <>
                <Link className="titles" to="/on-demand" onClick={cerrarMenu} style={{display:'none'}}>
                  {idioma === 'es' ?
                    <span className="seccion ondemand_btn" >
                      On demand
        </span>
                    :
                    <span className="seccion ondemand_btn" >
                      On demand
          </span>
                  }
                </Link>

              </>
              :
              <>
                {<Link className="titles" to="#" onClick={()=>{mensaje('warning',mensajelogin); setMostrarLogin(true); cerrarMenu();}} style={{display:'none'}}>
          <span className="seccion" >
            On demand
          </span>
        </Link>}

              </>
            }

           
              <>
                {usuario ?
                  <Link className="titles menu_mostrar_movil" to="/cerrar-sesion" onClick={() => { setMostrarLogin(true); cerrarMenu(); }}>
                    {idioma === 'es' ?
                      <span className="seccion" >
                        Cerrar sesión
        </span>
                      :
                      <span className="seccion" >
                        Log out
          </span>
                    }
                  </Link>
                  :

                  <a className="titles registro_btn menu_mostrar_movil" href="/registro"  style={{display:'none'}}>
                    {idioma === 'es' ?
                      <span className="seccion" >
                        REGISTRO
        </span>
                      :
                      <span className="seccion" >
                        SIGN UP
          </span>
                    }
                  </a>
                }
              </>
              

          </>

        

      </Col>
      
      
      <Col xs={{ span: 0, offset: 0 }} md={{ span: 3, offset: 0 }} style={{display:'none'}}>
        {usuario ?
          <Link className="titles" to="/cerrar-sesion" onClick={() => { setMostrarLogin(true); cerrarMenu(); }}>
            {idioma === 'es' ?
              <span className="seccion" >
                Cerrar sesión
        </span>
              :
              <span className="seccion" >
                Log out
          </span>
            }
          </Link>
          :
          <a className="titles registro_btn" href="/registro" style={{display:'none'}}>
            {idioma === 'es' ?
              <span className="seccion" >
                REGISTRO
        </span>
              :
              <span className="seccion" >
                SIGN UP
          </span>
            }
          </a>
        }
      </Col>

      <Modal
        title={<span><strong>{idioma === 'es' ? 'Inicia sesión' : 'Login'}</strong> <small>{idioma === 'es' ? '(únicamente con registro previo)' : '(Only with previu Register)'}</small></span>}
        visible={mostrar_login}//mostrar_login
        onOk={() => { setMostrarLogin(false) }}
        onCancel={() => { setMostrarLogin(false) }}
        footer={null}
        bodyStyle={{backgroundColor:'#fff'}}
      >
        <Login onResponseCerrarLogin={() => { setMostrarLogin(false); }} onResponse={() => { setMostrarLogin(false); window.location.href = "/on-demand"; }} idioma={idioma} onClick={success} />
      </Modal>

    </Row>
  );
}

export default Header;