import React, { useContext, useRef, useState, useEffect} from 'react';
import {} from 'react-router-dom';
import { Row, Col, Form, Input, Button, notification, Modal, Tabs } from 'antd';

import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';

import '../../assets/css/sala2.css';

import PopUpPregunta from './PopUpPregunta';


const { TextArea } = Input;

const app = new Firebase();

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Live2 = (props) =>{

  console.log(props);
  const [mis_preguntas, misPreguntas] = useState(false);
  const [en_vivo, enVivo] = useState(false);
  const [preguntas_contestadas, preguntasContestadas] = useState(true);

  const [modal_cuestionario, setModalCuestionario] = useState(false);
  const [num_pregunta, setNumPregunta] = useState(0);
  const {url, url_eng, comentarios, webinar, urlfb, urltw, urlyt, urlln, idioma, dia } = props;
  const [preguntas_popup_usuario, setPreguntasPopUpUsuario] = useState({});

  console.log('dia: ',dia);

  const { usuario, datos } = useContext(Auth);

  const ref_form_pregunta = useRef();

/* descomentar esto*/
  useEffect(() => {
    if(usuario){
      console.log('agregar');
      app.obtener_objeto(webinar+`/respuestas-usuarios-en-vivo${dia}`,'correo',datos.correo, (data) => {
      setPreguntasPopUpUsuario(data);
    });
    }else{
      window.location.href="/";
    }
  }, [usuario,dia]);


  useEffect(() => {

    if( Object.keys(preguntas_popup_usuario).length !== 0){

      app.obtener_objetos(webinar+'/configuracion', true, (data) => {
        
        setNumPregunta(data[0].pregunta_popup);
  
        if(data[0].pregunta_popup === 0){
          setModalCuestionario(false);
        }else{
            if(preguntas_popup_usuario['respuesta'+data[0].pregunta_popup] === undefined || preguntas_popup_usuario['respuesta'+data[0].pregunta_popup] === null){
              setModalCuestionario(true);
            }else{
              setModalCuestionario(false);
            }
          
        }
  
      });

    }

  },[preguntas_popup_usuario]);

  const onResponseCuestionario = (valor_respuesta) => {
    setModalCuestionario(false);
/* descomentar esto*/
    preguntas_popup_usuario['correo'] = datos.correo;
    preguntas_popup_usuario['respuesta'+num_pregunta] = valor_respuesta;

    console.log(preguntas_popup_usuario);
    console.log(dia);
    
    app.escribir(webinar+`/respuestas-usuarios-en-vivo${dia}`,preguntas_popup_usuario.key,preguntas_popup_usuario, (item) => {
      mensaje('success',idioma === 'es' ? 'Gracias por tu participación': 'Thanks for your participation','');
    });

  }

  
    let visualizar_preguntas = comentarios.filter((pre) => {
        if(pre.status === 'aceptada'){
          return true;
        }else{
          return false;
        }
    }).map((pre)=>{
      return(
        <div className="balloon">
          <p className="texto-preguntas">
          <span className="text-pre">{pre.pregunta}</span><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.organizacion}</span></strong>
            </p>
        </div>
      );
    });

    let ver_mispreguntas = comentarios.filter((pre) => {

      if(pre.correo === datos.correo){
        return true;
      }else{
        return false;
      }
  }).map((seccion)=>{
      return(
        <div className="balloon">
        <p className="texto-preguntas">
        <span className="text-pre">{seccion.pregunta}</span><br />
          <strong><span className="text-nom">{seccion.status}</span></strong>
          </p>
      </div>
      );
    });

    let ver_contestadas = comentarios.filter((pre) => {
      if(pre.status === 'contestada'){
        return true;
      }else{
        return false;
      }
  }).map((pre)=>{
      return(
        <div className="balloon">
        <p className="texto-preguntas">
          <span className="text-pre">{pre.pregunta}</span><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.organizacion}</span></strong>
            </p>
      </div>
      );
    });

  const onFinish = values => {
  
    let pregunta = {
      organizacion: datos.empresa,
      nombre: datos.nombre+' '+datos.ap_paterno+' '+datos.ap_materno,
      pregunta: values.pregunta,
      correo: datos.correo,
      status: 'pendiente'
    }

    app.escribir(webinar+`/preguntas-publico${dia}`,undefined, pregunta, () => {
      mensaje('success',idioma === 'es' ? 'Tu pregunta ha sido enviada':'Your question has been sent');
      ref_form_pregunta.current.resetFields();
    });
  };


  return(
  <Row align="top" className="" >
<Col span={0}>
<br />
</Col>

<Col xs={{span:20, offset:2}} md={{span:22, offset:1}}>
{idioma == 'es' ?
<p className="titulo_webinar" style={{textAlign:'center'}}><strong className="">XXIII Seminario de Iluminación IES México</strong>
      </p>
      :
      <p className="titulo_webinar" style={{textAlign:'center'}}><strong className="">XXIII Lighting Seminar IES México</strong>
      </p>}
</Col>
    <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:20, offset:2}} lg={{span:16, offset:0}} style={{textAlign:'center'}}>
  
      { url !== null ?
      (
      <>

        <div style={{padding:"56.25% 0 0 0", position:"relative", display:'non'}}>
          <iframe src={idioma === 'es' ? url : url_eng} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}}></iframe>
        </div>
      
     
      </>
      ):
      (
        <>
        
        
        </>
      )  
      
      
    }

    
    </Col>
    { url !== null ?
      (<>
        <Col xs={{span:20, offset:2}} lg={0} style={{display:'none'}}>
          <p className="clic_ver color_azul"><strong>Si no puedes visualizar la conferencia da clic en
            <a href={urlyt} target="_blank" className="color_azul"> Youtube</a>
            { urlfb !== null ?
              (<>
                
              </>)
              :
              (
                <> 
                </>
              )  
            }
            { urlln !== null ?
              (<>
                
              </>)
              :
              (
                <> 
                </>
              )  
            }
            </strong>
          </p>
        </Col>
        </>)
        :
        (
          <> 
          </>
        )  
      }

<Col xs={24} lg={0}>
      <br />
    </Col>
    <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:20, offset:2}} lg={{span:8, offset:0}} className="contenedor_live_preguntas" style={{display:'none'}}>
      <Row justify="center">
        <Col xs={{span:24}} lg={{span:0}}><br /></Col>
        <Col xs={{span:24, offset:0}} md={{span:24, offset:0}} lg={{span:23, offset:1}} className="header-preguntas">
          <Row align="middle">
            <Col span={22} offset={1}>
              <p className="pregunta-p">
                {idioma === 'es' ?
                <strong>PREGUNTA AL CONFERENCISTA</strong>
              :
              <strong>QUESTION TO THE SPEAKER</strong>}
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={{span:24, offset:0}} lg={{span:23, offset:1}}>
          <Row>
            <Col span={0} className={mis_preguntas === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              {idioma === 'es' ?
              <p style={{marginBottom:'0px'}} onClick={() => (misPreguntas(true), enVivo(false), preguntasContestadas(false))}>Mis preguntas</p>
            :
            <p style={{marginBottom:'0px'}} onClick={() => (misPreguntas(true), enVivo(false), preguntasContestadas(false))}>My questions</p>}
            </Col>
            <Col span={0} className={en_vivo === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              {idioma === 'es' ?
              <p style={{marginBottom:'0px'}} onClick={() => (enVivo(true), misPreguntas(false), preguntasContestadas(false))}>En vivo</p>
            :
            <p style={{marginBottom:'0px'}} onClick={() => (enVivo(true), misPreguntas(false), preguntasContestadas(false))}>Live</p>}
            </Col>
            <Col span={24} className="tipo_preguntas_select">
              {idioma === 'es' ?
              <p style={{marginBottom:'0px'}} onClick={() => (enVivo(false), misPreguntas(false), preguntasContestadas(true))}>Contestadas</p>
              :
              <p style={{marginBottom:'0px'}} onClick={() => (enVivo(false), misPreguntas(false), preguntasContestadas(true))}>Answer</p>}
            </Col>
            </Row>
        </Col>
        <Col xs={0}  md={{span:24, offset:0}} lg={{span:23, offset:1}} className="ver-preguntas" >
          <Row>
            <Col span={24}>
            {mis_preguntas && (
              <div>
                {ver_mispreguntas}
                </div>
            )}
            {en_vivo && (
              <div>
              {visualizar_preguntas}</div>
            )}
            {preguntas_contestadas && (
              <div>
              {ver_contestadas}</div>
            )}
            </Col>
          </Row>
        </Col>
        <Col xs={{span:24, offset:0}} md={0}  >
        <Row>
          <Col span={24} className="tipo_preguntas_select">
              <p style={{marginBottom:'0px'}}>
                {idioma === 'es' ? 'Contestadas' : 'Answer' }</p>
            </Col>
            <Col span={24} className="ver-preguntas">
            
              <div>
              {ver_contestadas}</div>
          
            </Col>
        </Row>
        </Col>
        
        <Col xs={{span:24, offset:0}}  md={{span:24, offset:0}} lg={{span:23, offset:1}} className="contenedor_enviar back_negro">
        <Form
          {...layout}
          name="basic"
          initialValues={{
            pregunta:'',
            remember: true,
          }}
            onFinish={onFinish}
            ref={ref_form_pregunta}
          >
            <Row>
              <Col span={22} offset={1} >
                <Form.Item
                
                  name="pregunta"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor escribe una pregunta',
                    },
                  ]}
                >
                  <TextArea className="text_enviar" disabled={usuario ? true : true} />
                </Form.Item>
              </Col>
              <Col xs={{span:15, oofset:1}} md={{span:15, offset:1}}  >
                <p className="sujeto" style={{ padding:'5px 0px 0px 5px'}}>
                  
                      <span>* 
                        {idioma === 'es' ?
                        <>Todas las preguntas están sujetas a aprobación</>
                        :
                        <>All questions are subject to approval</> 
                        }</span>
                  
                  
                  </p>
              </Col>
              <Col xs={{span:7, offset:1}}  style={{textAlign:'center', paddingTop:'3px', paddingBottom: '2px'}}>
                <Form.Item >
                  <Button type="" htmlType="submit" className="btn-enviar " disabled={usuario ? false : true}>
                    {idioma === 'es' ? <>Enviar</>: <>Send</>}
                  </Button>
                </Form.Item>
              </Col>
              </Row>
        </Form>
      </Col>
      
    </Row>
    </Col>
    { url !== null ?
      (<>
        <Col xs={0} lg={{span:20, offset:2}} style={{display:'none'}}>
          <p className="clic_ver color_azul"><strong>Si no puedes visualizar la conferencia da clic en
            <a href={urlyt} target="_blank" className="color_azul"> Youtube</a>
            { urlfb !== null ?
              (<>
                
              </>)
              :
              (
                <> 
                </>
              )  
            }
            { urlln !== null ?
              (<>
                
              </>)
              :
              (
                <> 
                </>
              )  
            }
            </strong>
          </p>
        </Col>
         </>)
         :
         (
           <> 
           </>
         )  
       }    
    <Col xs={24} md={0}>
      
    </Col>
    <Modal
          title={<span style={{ textAlign:'center', fontWeight:'bold'}} className="color_azul">{idioma === 'es' ? 'PARTICIPA EN NUESTRA ENCUESTA DE SATISFACCIÓN' : 'PARTICIPATE IN OUR SATISFACTION SURVEY'}</span>}
          visible={modal_cuestionario}
          footer={null}
          onCancel={() => setModalCuestionario(false)}
        >
          <PopUpPregunta num_pregunta={num_pregunta} onResponse={onResponseCuestionario} idioma={idioma} dia={dia} />
        </Modal>
   </Row>
  );
}

export default Live2;