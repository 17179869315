import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
import { pago_registro, status_registro } from './ConexionServidor';
import { notification } from 'antd';
import { Row, Col, Spin } from 'antd';
import { Input, Space } from 'antd';
import { AudioOutlined } from '@ant-design/icons';

import banner_registro from '../assets/images/impacto-zero/ImpactoZ_BannerPrincipal.jpg';
import logo_paypal from '../assets/images/impacto-zero/ImpactoZero_Logo_COLOR.png';


import '../assets/css/paypal.css';

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
}

const { Search } = Input;

const PayPal = (props) => {

  const [datos, setDatos] = useState([]);
  const [pago_exitoso, setPagoExitoso] = useState(-1);
  const [cargar_pantalla, setCargarPantalla] = useState(false);
  const location = useLocation();
  const { idioma } = props;

  //let datos = [0,1220,12200];

  useEffect(() => {

    if (location.hash.replace('#', '') !== '') {
      buscarRegistro(location.hash.replace('#', ''));
    }

  }, []);

  const onSearch = value => {
    if (value !== '' && value !== ' ') {
      buscarRegistro(value);
    } else {
      mensaje('warning', idioma === 'es' ? 'Ingresa un correo válido' : 'Enter a valid email', '');
    }
  }

  const buscarRegistro = (dato) => {
    setCargarPantalla(true);
    status_registro(dato).then(({ status, data }) => {
      switch (status) {
        case 0:
          setDatos(data);
          mensaje('success', idioma === 'es' ? 'Registro completo' : 'Complete registration', idioma === 'es' ? 'Todo se encuentra en orden' : 'Everything is in order');
          setPagoExitoso(1);
          break;
        case 1:
          setDatos(data);
          mensaje('warning', idioma === 'es' ? 'Pago pendiente' : 'Pending payment', idioma === 'es' ? 'Por favor realiza tu pago' : 'Please make your payment');
          setPagoExitoso(0);
          break;
        default:
          mensaje('error', idioma === 'es' ? 'Advertencia' : 'Warning', data);
          break;
      }
      setCargarPantalla(false);
    });
  }


  return (

    <>
      <Spin spinning={cargar_pantalla} delay={0}>
        <Row justify="center" align="middle">

        <Col xs={{ span: 24, offset: 0 }}>
                <img src={banner_registro} style={{ width: '100%' }} />
              </Col>
        </Row>
        {
          pago_exitoso === -1 ?
            <Row>
              <Col xs={{ span: 16, offset: 4 }}>
                <img src={logo_paypal} style={{ width: '100%' }} />
              </Col>
              <Col span={20} offset={2}>
                <br /><br />
                <p className="ingresa_correo">
                  {idioma === 'es' ?
                    <>Ingresa tu correo electrónico con el que te registraste</>
                    :
                    <>Enter your email with which you registered</>
                  }
                </p>
                <Space direction="vertical" style={{ width: '100%' }} width="100%">
                  <Search placeholder="Correo" onSearch={onSearch} enterButton style={{ width: '100%' }} width="100%" />
                </Space>
                <br /><br /><br />
                <Link to="/registro" className="boton_inicio">
                  {idioma === 'es' ?
                    <>REGÍSTRATE</>
                    :
                    <>SIGN UP</>
                  }

                </Link>
              </Col>
            </Row>
            :

            pago_exitoso === 0 ?

              <Row>
                <Col xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }}>
                  <br /><br /><br />
                  <img src={logo_paypal} style={{ width: '100%' }} />
                </Col>
                <Col xs={{ span: 18, offset: 3 }} md={{ span: 14, offset: 5 }}>
                  <hr className="line_paypal" />
                </Col>
                <Col span={24}>

                  <p className="texto_paypal">
                    <strong>
                      {idioma === 'es' ?
                        <>Por favor realiza el pago</>
                        :
                        <>Please make the payment</>
                      }
                    </strong>
                    <br />
                    {idioma === 'es' ?
                      <>Total (Impuestos incluidos)</>
                      :
                      <>Total (Tax included)</>
                    }
                    :
                  </p>
                  <p className="total_paypal">
                    <span>${new Intl.NumberFormat().format(datos[2])}<sup>{datos[5]}</sup></span>
                  </p>
                </Col>
                <Col xs={{ span: 18, offset: 4 }} md={{ span: 8, offset: 8 }}>
                  <p className="mini_texto_paypal">
                    {idioma === 'es' ?
                      <>Al hacer clic en "Paypal" sera dirigido a Paypal para completar el pago de manera mas segura.</>
                      :
                      <>By clicking on "Paypal" you will be directed to Paypal to complete the payment in a more secure way.</>
                    }

                  </p>
                </Col>
                <Col xs={{ span: 18, offset: 4 }} md={{ span: 8, offset: 8 }}>
                  <br />
                  <PayPalButton
                    currency_code={datos[5]}
                    disable_funding='card'
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        purchase_units: [{
                          amount: {
                            value: `${datos[2]}`
                          }
                        }],
                      });
                    }}
                    onApprove={(data, actions) => {
                      // Capture the funds from the transaction
                      return actions.order.capture().then(function (details) {
                        const pago = {
                          registro_id: datos[0],
                          orden: details,
                          estado: details.status
                        };

                        // OPTIONAL: Call your server to save the transaction
                        pago_registro(pago).then(({ status, data }) => {
                          switch (status) {
                            case 0:
                              switch (details.status) {
                                case 'COMPLETED':
                                  mensaje('success', idioma === 'es' ? 'Registro completo' : 'Completed register', '');
                                  setPagoExitoso(1);
                                  break;
                                default:
                                  mensaje('warning', idioma === 'es' ? 'Pago incompleto' : 'Underpayment', '');
                                  setPagoExitoso(2);
                                  break;
                              }
                              break;
                            default:
                              mensaje('success', idioma === 'es' ? 'Registro incompleto' : 'Incomplete registration', data);
                              break;
                          }
                        });
                      });
                    }}

                    options={{
                      clientId: "Ae0v7fdChsE99fhS7oLKaSEJAv3D6CYlNo_1iz53aobZDerwXzuT3sfgCjnjTfZpzshLIe4ezfZ_KqbZ",
                      currency: datos[5],
                      disableFunding: 'card'
                    }}
                  />
                </Col>
              </Row>

              :
              pago_exitoso === 1 ?

                <Row>
                  <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 3 }}>
                    <Row>
                      <Col span={24}><br />
                        <p className="contenedor_registro_exitoso registro_exitoso">
                          <strong>

                            {idioma === 'es' ?
                              <>
                                PAGO EXITOSO
                              </>
                              :
                              <>

                                SUCCESSFUL REGISTRATION
                              </>
                            }

                          </strong>
                        </p>
                      </Col>
                      <Col span={24}>
                        <p className="registro_texto_ubicacion">
                          <br />
                          {idioma === 'es' ?
                            <><strong>GRACIAS POR TU REGISTRO,</strong><br />
                              Te esperamos en:<br />
                              <strong>Martes 13 de junio a las 8:00 h • en el Club de Industriales</strong></>
                            :
                            <><strong>THANKS FOR YOUR REGISTRATION,,</strong><br />
                              We wait for you at:<br />
                              <strong>Martes 13 de junio a las 8:00 h • en el Club de Industriales
</strong></>
                          }</p>
                        <br />
                      </Col>
                      <Col span={0}>
                        <p className="registro_exitoso">
                          {idioma === 'es' ?
                            <>INSTRUCCIONES DE ACCESO PARA LA REPETICIÓN</>
                            :
                            <>ACCESS INSTRUCTIONS FOR REPLAY</>
                          }</p>
                        <Row>
                          <Col span={2}>
                            <p className="numero_instruccion color_azul">1)</p>
                          </Col>
                          <Col span={22}>
                            <p className="texto_instruccion">
                              {idioma === 'es' ?
                                <>Ingresa a</>
                                :
                                <>Enter</>
                              } <Link to="/on-demand">seminariodeiluminacion.iesmexico.org/on-demand</Link></p>
                          </Col>
                          <Col span={2}>
                            <p className="numero_instruccion color_azul">2)</p>
                          </Col>
                          <Col span={22}>
                            <p className="texto_instruccion">
                              {idioma === 'es' ?
                                <>Copia el correo electrónico y pégalo en el cuadro correspondiente.</>
                                :
                                <>Copy the email and paste it in the corresponding box.</>
                              }
                            </p>
                          </Col>
                          <Col span={2}>
                            <p className="numero_instruccion color_azul">3)</p>
                          </Col>
                          <Col span={22}>
                            <p className="texto_instruccion">
                              {idioma === 'es' ?
                                <>Repetir el paso anterior con tu contraseña.</>
                                :
                                <>Repeat the previous step with your password.</>
                              }
                            </p>
                          </Col>
                          <Col span={2}>
                            <p className="numero_instruccion color_azul">4)</p>
                          </Col>
                          <Col span={22}>
                            <p className="texto_instruccion">
                              {idioma === 'es' ?
                                <>Dar clic en “ingresar” y disfrutar del seminario.</>
                                :
                                <>Click on "enter" and enjoy the seminar.</>
                              }
                            </p>
                          </Col>
                        </Row>


                      </Col>
                    </Row>
                  </Col>
                </Row>
                :

                <Row>
                  <Col xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }}>
                    <br /><br /><br />
                    <img src={logo_paypal} style={{ width: '100%' }} />
                  </Col>
                  <Col xs={{ span: 18, offset: 3 }} md={{ span: 14, offset: 5 }}>
                    <hr className="line_paypal_rechazado" />
                  </Col>
                  <Col span={18} offset={3}>
                    <Row>


                      <Col span={24}>
                        <p className="registro_exitoso">
                          <strong>
                            {idioma === 'es' ?
                              <>PAGO RECHAZADO</>
                              :
                              <>PAYMENT DECLINED</>
                            }
                          </strong>
                        </p>
                        <p className="texto_registro_exitoso">
                          {idioma === 'es' ?
                            <>Hubo un error al procesar su pago.
                              <br />
                              Por favor contáctese con el banco emisor de su tarjeta.</>
                            :
                            <>
                              There was an error processing your payment.
                              <br />
                              Please contact the bank that issued your card</>
                          }


                        </p>
                        <Link to="/" className="boton_inicio">
                          {idioma === 'es' ?
                            <>VOLVER AL INICIO</>
                            :
                            <>
                              BACK TO TOP</>
                          }

                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>


        }
        <br /><br /><br />
      </Spin>
    </>



  );

}

export default PayPal;