import React, { useContext, useRef, useState, useEffect } from 'react';
import { } from 'react-router-dom';
import { Row, Col, Form, Input, Button, notification, Modal, Tabs } from 'antd';

import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';

import miercoles_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Miercoles_ENG.png';
import miercoles_hover_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Miercoles_scroll_ENG.png';
import jueves_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Jueves_ENG.png';
import jueves_hover_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Jueves_scroll_ENG.png';
import miercoles from '../../assets/images/2023/botones/ies_boton_Miercoles.png';
import miercoles_hover from '../../assets/images/2023/botones/ies_boton_Miercoles_scroll.png';
import jueves from '../../assets/images/2023/botones/ies_boton_Jueves.png';
import jueves_hover from '../../assets/images/2023/botones/ies_boton_Jueves_scroll.png';

import '../../assets/css/conferencias.css';

import conferencia_1 from '../../assets/images/2023/conferencias/portada_DanielaViloria.jpg';
import conferencia_1_en from '../../assets/images/2023/conferencias/portada_DanielaViloria_ENG.jpg';
import conferencia_2 from '../../assets/images/2023/conferencias/portada_FrankAgraz.jpg';
import conferencia_2_en from '../../assets/images/2023/conferencias/portada_FrankAgraz_ENG.jpg';
import conferencia_3 from '../../assets/images/2023/conferencias/portada_NickAlbert.jpg';
import conferencia_3_en from '../../assets/images/2023/conferencias/portada_NickAlbert_ENG.jpg';
import conferencia_4 from '../../assets/images/2023/conferencias/portada_Panel01.jpg';
import conferencia_4_en from '../../assets/images/2023/conferencias/portada_Panel01_ENG.jpg';
import conferencia_5 from '../../assets/images/2023/conferencias/portada_PaulinaVillalobos.jpg';
import conferencia_5_en from '../../assets/images/2023/conferencias/portada_PaulinaVillalobos_ENG.jpg';
import conferencia_6 from '../../assets/images/2023/conferencias/portada_PedroGarza.jpg';
import conferencia_6_en from '../../assets/images/2023/conferencias/portada_PedroGarza_ENG.jpg';
import conferencia_7 from '../../assets/images/2023/conferencias/portada_CeciliaRamos.jpg';
import conferencia_7_en from '../../assets/images/2023/conferencias/portada_CeciliaRamos_ENG.jpg';
import conferencia_8 from '../../assets/images/2023/conferencias/portada_Panel02.jpg';
import conferencia_8_en from '../../assets/images/2023/conferencias/portada_Panel02_ENG.jpg';
import conferencia_9 from '../../assets/images/2023/conferencias/portada_TonyEsposito.jpg';
import conferencia_9_en from '../../assets/images/2023/conferencias/portada_TonyEsposito_ENG.jpg';
import conferencia_10 from '../../assets/images/2023/conferencias/portada_ChiaraCarucci.jpg';
import conferencia_10_en from '../../assets/images/2023/conferencias/portada_ChiaraCarucci_ENG.jpg';
import conferencia_11 from '../../assets/images/2023/conferencias/portada_KatiaKolovea.jpg';
import conferencia_11_en from '../../assets/images/2023/conferencias/portada_KatiaKolovea_ENG.jpg';


const app = new Firebase();
const mensaje = (tipo, titulo, texto) => {

    notification[tipo]({
        message: titulo,
        description: texto,
    });

}
const layout = {
    wrapperCol: {
        span: 24,
    },
};


const Conferencias = (props) => {

    console.log(props);

    const { url, url_eng, comentarios, webinar, urlfb, urltw, urlyt, urlln, idioma, dia } = props;

    console.log('dia: ', dia);

    const { usuario, datos } = useContext(Auth);

    const [preguntas_popup_usuario, setPreguntasPopUpUsuario] = useState({});
    const ref_form_pregunta = useRef();

    /* descomentar esto*/
  useEffect(() => {
    if(usuario){
      console.log('agregar');
      app.obtener_objeto(webinar+`/respuestas-usuarios-en-vivo${dia}`,'correo',datos.correo, (data) => {
      setPreguntasPopUpUsuario(data);
    });
    }else{
      window.location.href="/";
    }
  }, [usuario,dia]);

    const [select_video, setSelectVideo] = useState(0);
    const [ver_dia, setVerDia] = useState(1);
    const [ver_modal, setVerModal] = useState(false);

    const videos = [
        {
            dia: 1, links: [
                //{index:1,url:'829520761',nombre:'Inauguración',url_en:'',nombre_en:'',img:conferencia_1,img_en:conferencia_1_en},
                { index: 2, url: '829531281', nombre: 'Diseñamos Humanos a través de la Luz', url_en: '830565829', nombre_en: 'We Design Humans Through Light', img: conferencia_1, img_en: conferencia_1_en },
                { index: 3, url: '829929965', nombre: 'Confesiones de un Auditor de Iluminación de la Vieja Guardia', url_en: '830554788', nombre_en: 'Confessions of an Old School Lighting Auditor', img: conferencia_2, img_en: conferencia_2_en },
                { index: 4, url: '829677106', nombre: 'Privilegio de Luz: Un marco práctico de iluminación holística en comunidades desatendidas', url_en: '830405616', nombre_en: 'Light Privilege: A practical framework for holistic lighting in underserved communities', img: conferencia_3, img_en: conferencia_3_en },
                { index: 5, url: '829502798', nombre: 'La evolución del Diseño de Iluminación en México', url_en: '830412495', nombre_en: 'The Evolution of the Lighting Design in Mexico', img: conferencia_4, img_en: conferencia_4_en },
                { index: 6, url: '829683497', nombre: 'Diseñar la Luz, Proteger la Oscuridad', url_en: '830541469', nombre_en: 'Designing the Light, Protecting the Darkness', img: conferencia_5, img_en: conferencia_5_en },
            ]
        },
        {
            dia: 2, links: [
                { index: 7, url: '829230211', nombre: 'Control de Iluminación: Creatividad y Técnica en un Análisis Multifactorial ', url_en: '830395572', nombre_en: 'Lighting Control: Creativity and Technique in a Multifactorial Analysis', img: conferencia_6, img_en: conferencia_6_en },
                { index: 8, url: '829300269', nombre: 'Color Fuera de las Pautas: superando los límites de la experiencia y el diseño', url_en: '830374349', nombre_en: 'Color Outside the Lines: pushing the boundaries of experience and design', img: conferencia_7, img_en: conferencia_7_en },
                { index: 9, url: '829641178', nombre: 'Presente y Futuro de la Industria de Iluminación en México', url_en: '830328940', nombre_en: 'Present and Future of the Lighting Industry in Mexico', img: conferencia_8, img_en: conferencia_8_en },
                { index: 10, url: '829262141', nombre: '¡Domina el Rendimiento de Color!', url_en: '830361521', nombre_en: 'Get a grip on Color Rendition!', img: conferencia_9, img_en: conferencia_9_en },
                { index: 11, url: '829653537', nombre: '¿Derecho o privilegio? Sustentabilidad en el Diseño de Iluminación', url_en: '830385433', nombre_en: 'Right or Privilege? Sustainability in Lighting Design', img: conferencia_10, img_en: conferencia_10_en },
                { index: 12, url: '829664111', nombre: 'Luz: Un poderoso lenguaje no-verbal', url_en: '830347828', nombre_en: 'Light: A powerful non-verbal language', img: conferencia_11, img_en: conferencia_11_en },
            ]
        }
    ]

    const ver_videos = videos.filter((video) => {
        if (video.dia === ver_dia)
            return true;
        else
            return false;
    }).map((vidio) => {
        return (
            <>
                {vidio.links.map((item) => {
                    return (
                        <Col xs={6} md={7} onClick={() => { setVerModal(true); setSelectVideo(item.index) }} className='puntero_clic'>
                            <img src={idioma === 'es' ? item.img : item.img_en} style={{ width: '100%' }} />
                            <p className='nombre_conferencias'>
                                {idioma === 'es' ? item.nombre : item.nombre_en}
                            </p>
                        </Col>
                    )
                })}
            </>
        )
    });

    const ver_select_video = videos.map((vidio) => {
        return (
            <>
                {vidio.links.filter((personas) => {
                    if (personas.index === select_video)
                        return true;
                    else
                        return false;
                }).map((item) => {
                    return (<>
                        <p className='modal_titulo_conferencia'>
                            {idioma === 'es' ? item.nombre : item.nombre_en}
                        </p>
                        <div className="contenedor_video">
                            <iframe className="video" src={idioma === 'es' ? 'https://player.vimeo.com/video/' + item.url : 'https://player.vimeo.com/video/' + item.url_en} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen;" allowfullscreen="1"></iframe>
                        </div>
                    </>
                    )
                })}

            </>)
    });

    return (
        <>
            <Row justify='center' align="top">
                <Col span={24}>
                    <br /><br /><br /><br />
                </Col>

                <Col span={20} className="contenedor_conferencias" >
                    
                    <p className='titulo_seccion'>CONFERENCIAS</p>
                            <Row justify='center'>
                                <Col xs={12} md={8} onClick={() => { setVerDia(1) }} className="puntero_clic">
                                    {idioma === 'es' ?
                                        <img src={ver_dia === 1 ? miercoles_hover : miercoles} style={{ width: '100%' }} />
                                        :
                                        <img src={ver_dia === 1 ? miercoles_hover_en : miercoles_en} style={{ width: '100%' }} />}
                                </Col>
                                <Col xs={12} md={8} onClick={() => { setVerDia(2) }} className="puntero_clic">
                                    {idioma === 'es' ?
                                        <img src={ver_dia === 2 ? jueves_hover : jueves} style={{ width: '100%' }} />
                                        :
                                        <img src={ver_dia === 2 ? jueves_hover_en : jueves_en} style={{ width: '100%' }} />}
                                </Col>
                                <Col span={24}>
                                    <br /><br />
                                </Col>
                            </Row>
                        
                    <Row justify='space-around' align=''>
                        {ver_videos}
                    </Row>
                </Col>
                
                
            </Row>
            <Modal
                visible={ver_modal} onCancel={() => { setVerModal(false); }}
                title={null}
                footer={null}
                closable={true}
                width="750px"
                className=""
                forceRender={true}>
                {ver_select_video}
            </Modal>
        </>
    );
}

export default Conferencias;