import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
import { pago_registro } from './ConexionServidor';
import { notification } from 'antd';
import { Row, Col, Spin } from 'antd';

import '../assets/css/paypal.css';

import banner_registro from '../assets/images/impacto-zero/ImpactoZ_BannerPrincipal.jpg';
import logo_paypal from '../assets/images/impacto-zero/ImpactoZero_Logo_COLOR.png';
import logo_paypal_en from '../assets/images/2023/titulos/Titulos_ENG/ies_PagoVoces_ENG.png';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
}

const PayPal = (props) => {

  const { datos } = props;

  //let datos = [0, 1220, 100,0,0,'USD'];

  const [pago_exitoso, setPagoExitoso] = useState(0);
  const [cargar_pantalla, setCargarPantalla] = useState(false);

  useEffect(() => {
    if (datos[2] === 0) {
      setPagoExitoso(1);
    }
  }, [datos]);

  const { idioma } = props;
  console.log(idioma);

  return (

    <>
      <Spin spinning={cargar_pantalla} delay={0}>
        <Row>
        <Col xs={{ span: 24, offset: 0 }}>
                <img src={banner_registro} style={{ width: '100%' }} />
              </Col>
        </Row>
        {
          pago_exitoso === 0 ?

            <Row>
              <Col xs={{ span: 16, offset: 4 }}>
                <img src={logo_paypal} style={{ width: '100%' }} />
              </Col>
              <Col span={24}>

                {idioma === 'es' ?
                  <p className="texto_paypal">
                    <strong>Por favor realiza el pago</strong>
                    <br />
                    Total (Impuestos incluidos):
                  </p>
                  :
                  <p className="texto_paypal">
                    <strong>Please make the payment</strong>
                    <br />
                    Total:
                  </p>}
                <p className="total_paypal">
                  <span>${new Intl.NumberFormat().format(datos[2])}<sup>{datos[5]}</sup></span>
                </p>
              </Col>
              <Col xs={{ span: 18, offset: 4 }} md={{ span: 8, offset: 8 }}>
                {idioma === 'es' ?
                  <p className="mini_texto_paypal">
                    Al hacer clic en "Paypal" sera dirigido a Paypal para completar el pago de manera mas segura.
                  </p>
                  :
                  <p className="mini_texto_paypal">
                    By clicking on "Checkout", you will be directed to PayPal to complete the payment safely.
                  </p>
                }
              </Col>
              <Col xs={{ span: 18, offset: 4 }} md={{ span: 8, offset: 8 }}>
                <br />
                <PayPalButton
                  style={{
                    layout: 'vertical',
                    color: 'blue',
                    shape: 'pill',
                    label: 'paypal'
                  }}
                  currency_code={datos[5]}
                  disable_funding='card'
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [{
                        amount: {
                          value: `${datos[2]}`
                        }
                      }],
                    });
                  }}
                  onApprove={(data, actions) => {
                    // Capture the funds from the transaction
                    return actions.order.capture().then(function (details) {
                      const pago = {
                        registro_id: datos[0],
                        orden: details,
                        estado: details.status
                      };

                      // OPTIONAL: Call your server to save the transaction
                      pago_registro(pago).then(({ status, data }) => {
                        switch (status) {
                          case 0:
                            switch (details.status) {
                              case 'COMPLETED':
                                mensaje('success', 'Registro completo', '');
                                setPagoExitoso(1);
                                break;
                              default:
                                mensaje('warning', 'Pago incompleto', '');
                                setPagoExitoso(2);
                                break;
                            }
                            break;
                          default:
                            mensaje('success', 'Registro incompleto', data);
                            break;
                        }
                      });
                    });
                  }}

                  options={{
                    clientId: "Ae0v7fdChsE99fhS7oLKaSEJAv3D6CYlNo_1iz53aobZDerwXzuT3sfgCjnjTfZpzshLIe4ezfZ_KqbZ",
                    currency: datos[5],
                    disableFunding: 'card'
                  }}
                />
              </Col>
            </Row>

            :
            pago_exitoso === 1 ?

              <Row>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 18, offset: 3 }} >
                  <Row>
                    <Col span={24}>
                      <br /><br />
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_registro_exitoso registro_exitoso">
                        {idioma === 'es' ?
                          <><strong>REGISTRO EXITOSO</strong> <CheckCircleOutlined style={{ color: '#1fa264' }} /></>
                          :
                          <strong>SUCCESSFUL REGISTRATION</strong>
                        }
                      </p>
                    </Col>
                    <Col span={24}>
                      <p className="registro_texto_ubicacion">
                        <br />
                        <><strong>GRACIAS POR TU REGISTRO,</strong><br />
                            Te esperamos en:<br />
                            <strong>Club de Industriales - CDMX</strong></>
                            </p>
                      {idioma === 'es' ?
                        <p className="texto_registro_exitoso">
                          En breve recibirá un correo de confirmación a la siguiente dirección
                          <br />
                          <strong>{datos[3]}</strong>
                        </p>
                        :
                        <p className="texto_registro_exitoso">
                          You will shortly receive a confirmation email to the following address:
                          <br />
                          <strong>{datos[3]}</strong>
                        </p>}
                      {idioma === 'es' ?
                        <p className="texto_registro_exitoso color_verde">
                          Si no encuentra el mail confirmación en su bandeja de entrada, es posible el mensaje<br />
                          haya sido enviado a la carpeta de SPAM / NO DESEADOS.
                        </p>
                        :
                        <p className="texto_registro_exitoso color_verde">
                          If you cannot find the confirmation email in your inbox, it is possible the message <br />has been sent to the SPAM / UNWANTED folder.
                        </p>}
                        </Col>
                  </Row>
                </Col>
              </Row>
              :

              <Row>
                <Col xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }}>
                  <br /><br /><br />
                  <img src={logo_paypal} style={{ width: '100%' }} />
                </Col>
                <Col xs={{ span: 18, offset: 3 }} md={{ span: 14, offset: 5 }}>

                </Col>
                <Col span={18} offset={3}>
                  <Row>


                    <Col span={24}>
                      <p className="registro_exitoso">
                        {idioma === 'es' ?
                          <strong>PAGO RECHAZADO <CloseCircleOutlined style={{ color: 'red' }} /></strong>
                          :
                          <strong>PAYMENT DECLINED <CloseCircleOutlined /></strong>}
                      </p>
                      <hr className="line_paypal_rechazado" />
                      {idioma === 'es' ?
                        <p className="texto_registro_exitoso">
                          Hubo un error al procesar su pago.
                          <br />
                          Por favor contáctese con el banco emisor de su tarjeta.
                        </p>
                        :
                        <p className="texto_registro_exitoso">
                          There was an error processing your payment.
                          <br />
                          Please contact the bank that issued your card.
                        </p>}
                      {idioma === 'es' ?
                        <Link to="/" className="boton_inicio">
                          VOLVER AL INICIO
                        </Link>
                        :
                        <Link to="/" className="boton_inicio">
                          BACK TO HOME
                        </Link>}
                    </Col>
                  </Row>
                </Col>
              </Row>


        }
        <br /><br /><br />
      </Spin>
    </>



  );

}

export default PayPal;