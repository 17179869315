import React, {useState, useEffect} from 'react';
import Firebase from '../../utils/firebaseConfig';
import { Col, Row, Select, Card, Statistic } from 'antd';

const app = new Firebase();
const nombre_evento = 'impacto-zero';
const {Option} = Select;

const Metricas = ()=>{

    const [datos, setDatos] = useState([]);
    const [lives, setLives] = useState([]);
    const [preguntas, setPreguntas] = useState([]);
    const [popup, setPopup] = useState([]);
    const [dias, setDias] = useState(1);
    const [stand, setStand] = useState('');
    const [dia_preguntas,setDiaPreguntas] = useState(1);
    const [stand_clic,setStandClic] = useState('');
    

    useEffect(()=>{
        app.obtener_objetos(nombre_evento+`/stands`,true,(data) => {
            setDatos(data);
        });

        app.obtener_objetos(nombre_evento+'/respuestas-usuarios-en-vivo1',false,(data) => {
            setPopup(data);
        });

        

        app.obtener_objetos(nombre_evento+`/live`,true,(data) => {
            //console.log(Object.values(data));
            let vivo = [];
            for(let i=1; i <= 3; i++){
                vivo.push(
                    <Col span={8}>
                        <p>Día {i}: <strong>{data ? Object.values(data).filter(live => live.dia === i).length : 0}</strong></p>
                        <p>
                            <strong>Login: </strong> {data ? Object.values(data).filter(live => live.dia === i && live.login).length : 0}
                            <br />
                            <strong>Sin login: </strong> {data ? Object.values(data).filter(live => live.dia === i && (!live.login)).length : 0}
                            <br />
                        </p>
                    </Col>
                );
            }
            setLives(vivo);
        });

        app.obtener_objetos(nombre_evento + '/configuracion', false, (data) => {
            setDias(data[0].dia_metricas);
        });

    },[]);
    useEffect(()=>{
        app.obtener_objetos(nombre_evento+`/preguntas-publico`+dia_preguntas,false,(data) => {
            setPreguntas(data);
        });
    },[dia_preguntas]);

    return(
        <Row>
            <Col span={20} offset={2}>
                <br /><br />

                <table style={{display:'none'}}>
                    <tr>
                        <td >Correo</td>
                        <td>1</td><td>2</td><td>3</td>
                        <td>4</td><td>5</td><td>6</td>
                        <td>7</td><td>8</td><td>9</td>
                        <td>10</td>
                    </tr>
                    {popup.map(pregunta => {
                        return(
                            <tr>
                                <td style={{border:'1px solid black'}}>{pregunta.correo}</td>

                                <td style={{border:'1px solid black'}}>{pregunta.respuesta1}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta2}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta3}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta4}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta5}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta6}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta7}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta8}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta9}.</td>
                                <td style={{border:'1px solid black'}}>{pregunta.respuesta10}.</td>

                            </tr>
                        );
                    })
                    }
                </table>
                <Row justify='center' style={{display:'none'}}>
                    <Col span={24}><br /><br /></Col>
                    <Col span={4} onClick={()=>setDiaPreguntas(1)} style={{fontSize:'18px', cursor:'pointer', border:'2px solid black'}}>
                        Día 1
                    </Col>
                    <Col span={4} onClick={()=>setDiaPreguntas(2)} style={{fontSize:'18px', cursor:'pointer', border:'2px solid black'}}>
                        Día 2
                    </Col>
                    <Col span={4} onClick={()=>setDiaPreguntas(3)} style={{fontSize:'18px', cursor:'pointer', border:'2px solid black'}}>
                        Día 3
                    </Col>
                    <Col span={4} onClick={()=>setDiaPreguntas(4)} style={{fontSize:'18px', cursor:'pointer', border:'2px solid black'}}>
                        Día 4
                    </Col>
                    <Col span={4} onClick={()=>setDiaPreguntas(5)} style={{fontSize:'18px', cursor:'pointer', border:'2px solid black'}}>
                        Día 5
                    </Col>
                    <Col span={24}><br /><br /></Col>
                </Row>
                <table style={{display:'none'}}>
                    {preguntas.map(pregunta => {
                        return(
                            <tr>
                                <td style={{border:'1px solid black'}}>{pregunta.nombre}</td>
                                <td style={{border:'1px solid black'}}>{pregunta.organizacion}</td>
                                <td style={{border:'1px solid black'}}>{pregunta.correo}</td>
                                <td style={{border:'1px solid black'}}>{pregunta.pregunta}</td>
                            </tr>
                        );
                    })}
                </table>

                <h1 style={{display:'none'}}>LIVE</h1>
                <Row style={{display:'none'}}>
                {lives}
                </Row>
                
                <br /><br /><br />
            <h1 style={{display:'non'}}>STANDS</h1>
<div style={{display:'none'}}>{datos.filter(stand => stand.key !== 'UNDEFINED').map(stand => {
                let users = stand.stand ? Object.values(stand.stand) : [];
                
                return(
                    <table style={{border:'10px solid black'}}>
                        <tr><td>{stand.key}</td></tr>
                        <tr>
                        <th></th><th>Nombre</th><th>Correo</th><th>Empresa</th><th>Día</th>
                        </tr>
                        {users.map(user => 
                            <tr>
                                <th></th><th>{user.nombre + ' ' + user.ap_paterno}</th>
                                <th>{user.correo}</th><th>{user.empresa}</th><th>{user.dia}</th>
                            </tr>
                        )}

                    </table>
                );
            }
        )}</div>
            


            <table style={{display:'none'}}>
                    <tr>
                        <td>Correo</td><td>Dia</td>
                        <td>1</td><td>2</td><td>3</td>
                        <td>4</td><td>5</td><td>6</td>
                        <td>7</td><td>8</td><td>9</td>
                        <td>10</td>
                    </tr>
            </table>















            <Select defaultValue={""} onChange={val => setStand(val)} style={{width:'100%', display:'non'}}>
                <Option value="">Todos los stands</Option>
                {datos.map(stands => {
                    return(
                        <Option value={stands.key}>{stands.key.toUpperCase()}</Option>
                    );
                })}
            </Select>
            </Col>
            <Col span={22} offset={1} style={{display:'non'}}>
                <br /><br />
                {datos.filter(stands => { if(stand === ''){return true;}else{if(stand === stands.key){return true;}}return false;   }).map(stands => {
                    
                    let estadisticas_dia = [];
                    for(let i=0; i < dias; i++){
                        estadisticas_dia[i] = 
                        <Col xs={{span:24}} md={{span:8}} >
                            <Card 
                                title={
                                <Statistic title={<span>Visitas día: <strong>{i+1}</strong></span>} 
                                    value={0 + (stands.stand ? Object.values(stands.stand).filter(sta => sta.dia === (i+1)).length : 0) + (stands.stand_clic ? Object.values(stands.stand_clic).filter(sta => sta.dia === (i+1)).length : 0)} />} extra={null} style={{ width: '100%' }}
                            >
                                <Row>
                                    <Col span={12}></Col>
                                    <Col span={12}>Clics</Col>
                                    
                                    {/*Stand*/}
                                    <Col span={12}>Stand</Col>
                                    <Col span={12}>{stands.stand_clic ? Object.values(stands.stand_clic).filter(sta => sta.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Video*/}
                                    <Col span={12}>Video</Col>
                                    <Col span={12}>{stands.video_clic ? Object.values(stands.video_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Mail*/}
                                    <Col span={12}>Mail</Col>
                                    <Col span={12}>{stands.mail_clic ? Object.values(stands.mail_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Watsapp*/}
                                    <Col span={12}>Watsapp</Col>
                                    <Col span={12}>{stands.whatsapp_clic ? Object.values(stands.whatsapp_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Facebook*/}
                                    <Col span={12}>Facebook</Col>
                                    <Col span={12}>{stands.facebook_clic ? Object.values(stands.facebook_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Twitter*/}
                                    <Col span={12}>Twitter</Col>
                                    <Col span={12}>{stands.twitter_clic ? Object.values(stands.twitter_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Instragram*/}
                                    <Col span={12}>Instagram</Col>
                                    <Col span={12}>{stands.instagram_clic ? Object.values(stands.instagram_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Youtube*/}
                                    <Col span={12}>Youtube</Col>
                                    <Col span={12}>{stands.youtube_clic ? Object.values(stands.youtube_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                    {/*Linkedin*/}
                                    <Col span={12}>Linkedin</Col>
                                    <Col span={12}>{stands.linkedin_clic ? Object.values(stands.linkedin_clic).filter(stands => stands.dia === (i+1)).length : 0}</Col>
                                    
                                </Row>
                            </Card>
                            <br /><br />
                            </Col>
                        ;
                    }
                    return(
                        <Row justify="center">
                            {stands.key &&
                            <>
                                <Col span={24}>
                                    <p style={{fontSize:'20px', fontWeight:'bold'}}>{stands.key.toUpperCase()}</p>
                                </Col>
                                {estadisticas_dia}
                            </>
                            }
                        </Row>
                    );
                })}
            </Col>
            <Col>
            <br /><br /><br /><br />
            </Col>
            <Col xs={{span:0}} md={{span:24}}>
            <br /><br /><br /><br /> <br /><br /><br /><br />
            </Col>
        </Row>
    );
}

export default Metricas;
