import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import {useLocation, BrowserRouter} from 'react-router-dom';
import { HashLink, HashLink as Link } from 'react-router-hash-link';
import { HashScroll } from "react-hash-scroll";


import '../../assets/css/evento.css';

import Contador from  './Contador';
import CarouselPonentes from './CarouselPonentes';
import Agenda from './Agenda';
import Whatsapp from '../master/Whatsapp';
import { Element } from 'react-scroll';

import logo1 from '../../assets/images/sitio/02_Logos_sitio_Ies/Ies_LogoColorIES.png';
import logo2 from '../../assets/images/sitio/02_Logos_sitio_Ies/Ies_LogoMexico.png';
import logo3 from '../../assets/images/sitio/02_Logos_sitio_Ies/Ies_LogoMonterrey.png';


import logo_banner from '../../assets/images/2023/titulos/ies_header_voces.png';
import fecha_banner from '../../assets/images/2023/titulos/ies_header_fecha.png';
import img_registro_btn from '../../assets/images/2023/botones/ies_boton_Registrate.png';

import logo_banner_en from '../../assets/images/2023/titulos/Titulos_ENG/ies_header_voces_ENG.png';
import fecha_banner_en from '../../assets/images/2023/titulos/Titulos_ENG/ies_header_fecha_ENG.png';
import img_registro_btn_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Registrate_ENG.png';


import titulo_paquete from '../../assets/images/2023/titulos/ies_PlanDePrecios.png';
import titulo_paquete_en from '../../assets/images/2023/titulos/Titulos_ENG/ies_PlanDePrecios_ENG.png';


import paquete_general from '../../assets/images/2023/Precios/ies_precio_General.png';
import paquete_general_hover from '../../assets/images/2023/Precios/ies_precio_General_scroll.png';
import paquete_asociacion from '../../assets/images/2023/Precios/ies_precio_Asociaciones.png';
import paquete_asociacion_hover from '../../assets/images/2023/Precios/ies_precio_Asociaciones_scroll.png';
import paquete_miembros from '../../assets/images/2023/Precios/ies_precio_Miembros.png';
import paquete_miembros_hover from '../../assets/images/2023/Precios/ies_precio_Miembros_scroll.png';
import paquete_estudiantes from '../../assets/images/2023/Precios/ies_precio_Estudiantes.png';
import paquete_estudiantes_hover from '../../assets/images/2023/Precios/ies_precio_Estudiantes_scroll.png';

import paquete_general_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_General_ENG.png';
import paquete_general_hover_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_General_scroll_ENG.png';
import paquete_asociacion_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_Asociaciones_ENG.png';
import paquete_asociacion_hover_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_Asociaciones_scroll_ENG.png';
import paquete_miembros_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_Miembros_ENG.png';
import paquete_miembros_hover_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_Miembros_scroll_ENG.png';
import paquete_estudiantes_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_Estudiantes_ENG.png';
import paquete_estudiantes_hover_en from '../../assets/images/2023/Precios/precios_ENG/ies_precio_Estudiantes_scroll_ENG.png';

import AcercaDe from './AcercaDe';
import CarouselPanel from './CarouselPanel';


const paquetes = [
  {id_paquete:'1',url_imagen_hover:paquete_general_hover,url_imagen:paquete_general,url_imagen_hover_en:paquete_general_hover_en,url_imagen_en:paquete_general_en,nombre:'',link:'/registro/1'},
  {id_paquete:'2',url_imagen_hover:paquete_asociacion_hover,url_imagen:paquete_asociacion,url_imagen_hover_en:paquete_asociacion_hover_en,url_imagen_en:paquete_asociacion_en,nombre:'',link:'/registro/2'},
  {id_paquete:'3',url_imagen_hover:paquete_miembros_hover,url_imagen:paquete_miembros,url_imagen_hover_en:paquete_miembros_hover_en,url_imagen_en:paquete_miembros_en,nombre:'',link:'/registro/3'},
  {id_paquete:'4',url_imagen_hover:paquete_estudiantes_hover,url_imagen:paquete_estudiantes,url_imagen_hover_en:paquete_estudiantes_hover_en,url_imagen_en:paquete_estudiantes_en,nombre:'',link:'/registro/4'}
]

function onChange(a, b) {
  console.log(a, b);
}


const Evento = (props) =>{
  const [hover_imagen, setHoverImagen] = useState([false,false,false,false]);
  const [select_imagen, setSelectImagen] = useState([false,false,false,false]);
  const [paquete_elegido, setPaqueteElegido] = useState(1);
  const [ver_paquete, setVerPaquete] = useState(false);
  const location = useLocation();

  const {idioma} = props;

  const cambiarHoverImagen = (index) =>{

    let arreglo = [];
    for(let i=0; i < 5; i++){
      if(i === index){
        arreglo[i]=true;
      }else{
        arreglo[i]=false;
      }
    }
    console.log(arreglo);
    setSelectImagen(arreglo);
  }
  useEffect(()=>{console.log(hover_imagen)},[hover_imagen]);
  let ver_paquetes = paquetes.map((objeto,index)=>{
      
    return(
      <Col span={4}>
        <Link to={objeto.link}>
        <p onMouseEnter={()=>setVerPaquete(objeto.id_paquete)} onMouseLeave={()=>setVerPaquete(false)}>
          {idioma === 'es' ?
            <img src={ver_paquete === objeto.id_paquete ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{width:'100%', cursor:'pointer'}} />
            :
            <img src={ver_paquete === objeto.id_paquete ? objeto.url_imagen_hover_en : objeto.url_imagen_en} alt={objeto.nombre} style={{width:'100%', cursor:'pointer'}} />
          }
        </p>
        </Link>
      </Col>
    )
  });
  let ver_paquetes_movil = paquetes.map((objeto,index)=>{
    
    return(
      <Col span={10}>
        <Link to={objeto.link}>
        <p onClick={()=>{cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
          {idioma === 'es' ?
            <img src={select_imagen[index] ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{width:'100%', cursor:'pointer'}} />
          :
          <img src={select_imagen[index] ? objeto.url_imagen_hover_en : objeto.url_imagen_en} alt={objeto.nombre} style={{width:'100%', cursor:'pointer'}} />
          }
        </p>
        </Link>
      </Col>
    )
  });

  return(
    <>
    <Whatsapp idioma={idioma} />
    <Row justify="center" align="middle">
      <Col xs={0} md={24} className="principal">
        <Row>
          <Col span={12} offset={6}><br /><br /><br />
            {idioma === 'es' ?
              <img src={logo_banner} width="100%" />
            :
            <img src={logo_banner_en} width="100%" />
            }
          </Col>
          <Col span={14} offset={5}  style={{display:'none'}}>
           {idioma === 'es' ?
            <p className="titulo">
            El Seminario de Iluminación IES Mexico, es un evento educativo donde se difunden las últimas tendencias y adelantos tecnológicos en el mundo de la iluminación, abordando estos temas desde una perspectiva técnica o de diseño.
            </p>
            :
            <p className="titulo">
            The Lighting Seminar IES Mexico is an educational event where the latest trends and technological advances in the world of lighting are disseminated, addressing these issues from a technical or design perspective.
            </p>

           }
          </Col>
          <Col span={10} offset={7}  style={{display:'none'}}>
            {idioma === 'es' ?
              <img src={fecha_banner} width="100%" />
            :
            <img src={fecha_banner_en} width="100%" />
            }
          </Col>
          <Col span={6} offset={9}>
            <br /><br />
            {idioma === 'es' ?
              <a href="#registro"><img src={img_registro_btn} width="100%" /></a>
            :
            <a to="#registro"><img src={img_registro_btn_en} width="100%" /></a>
            }
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={0} className="principal_movil">
        <Row>
          <Col span={16} offset={4}>
          {idioma === 'es' ?
            <img src={logo_banner} width="100%" />
            :
            <img src={logo_banner_en} width="100%" />
            }
          </Col>
          <Col span={8} offset={8} style={{display:'none'}}>
          {idioma === 'es' ?
            <img src={fecha_banner} width="100%" />
            :
            <img src={fecha_banner_en} width="100%" />
          }
          </Col>
        </Row>
      </Col>
      </Row>
      <Row id="registro">
      <Element name="#registro" />
        <Col xs={{span:24, offset:0}} md={{span:24, offset:0}} className='paquetes_back'>
          <Row justify="center">
            <Col span={12}>
              <img src={idioma === 'es' ? titulo_paquete : titulo_paquete_en } style={{width:'100%'}} />
            </Col>
            <Col xs={0} md={24}>
              <Row justify="center">
                {ver_paquetes}
              </Row>
            </Col>
            <Col xs={24} md={0}>
              <Row justify="center">
                {ver_paquetes_movil}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row>
        <Col id="acerca-de" name="#acerca-de" spen={24}>
          <AcercaDe idioma={idioma} />
        </Col>
      </Row>
    <Element name="#agenda" />
      <Row justify="center" align="middle" >
      <Col span={24} className="" id="agenda">
      <HashScroll hash="#agenda" position="center">

        <Agenda idioma={idioma}/>
        </HashScroll>
      </Col>
      </Row>
      
      <Element name="#conferencistas" />
      <Row id="conferencistas" justify="center" align="middle">
      
        <Col span={24}>
          
          <CarouselPonentes idioma={idioma}/>
        </Col>
      </Row>
      <Element name="#panel" />
      <Row justify="center" align="middle" >
      <Col span={24} className="" id="panel">
      <HashScroll hash="#panel" position="center">

        <CarouselPanel idioma={idioma}/>
        </HashScroll>
      </Col>
      </Row>
      <Row justify="center" align="middle">
        
        </Row>
        
    </>
  );
}

export default Evento;