import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Modal, Spin, message, Button, notification, Typography, Input } from "antd";
import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';
import Live2 from '../conferencias/Live2';
import { Preguntas } from '../conferencias/Preguntas';
import { Estadisticas } from '../conferencias/Estadisticas';
import '../../assets/css/sala2.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';



import ponente1 from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_CarrieACCESO.jpg';
import ponente2 from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_Dario_ACCESO.jpg';
import ponente3 from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_Mark_ACCESO.jpg';
import ponente4 from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_Naomi_eACCESO.jpg';
import ponente1_eng from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_CarrieACCESO_eng.jpg';
import ponente2_eng from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_Dario_ACCESO_eng.jpg';
import ponente3_eng from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_Mark_ACCESO_eng.jpg';
import ponente4_eng from '../../assets/images/04_sala_de_conferencias/ponentes/Ies_MesasCruzadas_Naomi_eACCESO_eng.jpg';
import { defaultPrefixCls } from "antd/lib/config-provider";

const ponentes = [ponente1, ponente2, ponente3, ponente4, ponente1_eng, ponente2_eng, ponente3_eng, ponente4_eng];

const nombre_evento = 'impacto-zero';

const app = new Firebase();

let key = '';

const { Text } = Typography;

const success = () => {
    mensaje('success', 'Bienvenido', '');
};

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const lives = [
    {nombre:'Bienvenida',url:'https://www.youtube.com/embed/IoEXTSaoUWM?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/AFpnHCtM3_M?autoplay=1&rel=0&playsinline=1',dia:1,video:1, activar_preguntas:false},
    {nombre:'Las mutaciones de la iluminación urbana',url:'https://www.youtube.com/embed/hSbfYRbZr70?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/QsKCP5vNx_Q?autoplay=1&rel=0&playsinline=1',dia:1,video:2, activar_preguntas:false},
    {nombre:'Iluminación Social: Del discurso a la praxis',url:'https://www.youtube.com/embed/gV172RtURek?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/L-nNUfXNpHE?autoplay=1&rel=0&playsinline=1',dia:1,video:3, activar_preguntas:false},
    {nombre:'Fuerzas de cambio 2021',url:'https://www.youtube.com/embed/PQgxuCZQ9sA?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/HymAJZfeweo?autoplay=1&rel=0&playsinline=1',dia:1,video:4, activar_preguntas:false},

    {nombre:'',url:'https://www.youtube.com/embed/Vy_uLLDITJw?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/4ePtoISjX_w?autoplay=1&rel=0&playsinline=1',dia:2,video:1, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/Ac7oNz1LsCw?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/nCFSkE66sYg?autoplay=1&rel=0&playsinline=1',dia:2,video:2, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/p_JZmDFGLPA?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/WwJdGQSoBds?autoplay=1&rel=0&playsinline=1',dia:2,video:3, activar_preguntas:false},

    {nombre:'',url:'https://www.youtube.com/embed/pU9-EZWnuck?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/-O742zYRnTE?autoplay=1&rel=0&playsinline=1',dia:3,video:1, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/Z8KTRhKPiVQ?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/Z8KTRhKPiVQ?autoplay=1&rel=0&playsinline=1',dia:3,video:2, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/jrgOW-Gn1zk?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/jrgOW-Gn1zk?autoplay=1&rel=0&playsinline=1',dia:3,video:3, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/JyEYydYUWco?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/JyEYydYUWco?autoplay=1&rel=0&playsinline=1',dia:3,video:4, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/SPvzcFymaJc?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/SPvzcFymaJc?autoplay=1&rel=0&playsinline=1',dia:3,video:5, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/tE3Hp0Sw0R4?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/tE3Hp0Sw0R4?autoplay=1&rel=0&playsinline=1',dia:3,video:6, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/Aub-C1JYs7Q?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/Aub-C1JYs7Q?autoplay=1&rel=0&playsinline=1',dia:3,video:7, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/8Ni8qcM_5II?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/8Ni8qcM_5II?autoplay=1&rel=0&playsinline=1',dia:3,video:8, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/0ja0PMIVx0U?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/0ja0PMIVx0U?autoplay=1&rel=0&playsinline=1',dia:3,video:9, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/de0KszheLFA?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/de0KszheLFA?autoplay=1&rel=0&playsinline=1',dia:3,video:10, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/rsMj1wiEqwM?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/rsMj1wiEqwM?autoplay=1&rel=0&playsinline=1',dia:3,video:11, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/T8lJLVb84xY?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/T8lJLVb84xY?autoplay=1&rel=0&playsinline=1',dia:3,video:12, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/WkWjh_R8ZiE?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/WkWjh_R8ZiE?autoplay=1&rel=0&playsinline=1',dia:3,video:13, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/gMhYUgTKYnQ?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/gMhYUgTKYnQ?autoplay=1&rel=0&playsinline=1',dia:3,video:14, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/tyrs17hXgAI?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/tyrs17hXgAI?autoplay=1&rel=0&playsinline=1',dia:3,video:15, activar_preguntas:false},
    
    {nombre:'',url:'https://www.youtube.com/embed/IE6HU8lCqvw?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/F30Sdnv3EKY?autoplay=1&rel=0&playsinline=1',dia:4,video:1, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/E4CtIr5f3F8?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/6OO0O8GV0VY?autoplay=1&rel=0&playsinline=1',dia:4,video:2, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/O4BhY-mj-ZM?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/3Lb3luhMAAE?autoplay=1&rel=0&playsinline=1',dia:4,video:3, activar_preguntas:false},

    {nombre:'',url:'https://www.youtube.com/embed/Nln2kEKhIMA?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/oklZIePpjj0?autoplay=1&rel=0&playsinline=1',dia:5,video:1, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/JszqWwhc7L8?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/6X-rSR3-qKE?autoplay=1&rel=0&playsinline=1',dia:5,video:2, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/V5uTf3sRLWY?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/bGG9b7FSSJQ?autoplay=1&rel=0&playsinline=1',dia:5,video:3, activar_preguntas:false},
    {nombre:'',url:'https://www.youtube.com/embed/iiJKkyencj8?autoplay=1&rel=0&playsinline=1',url_ing:'https://www.youtube.com/embed/Yo1NdneCmAc?autoplay=1&rel=0&playsinline=1',dia:5,video:4, activar_preguntas:false},
]

const mesas = [
    {nombre:'Iluminando el camino hacia LEED Platinum', name:'Lighting the Way to LEED Platinum', numero:1},
    {nombre:'Mesa A. Carrie Hawley y Liliana Gonzáles', name:'Table A. Carrie Hawley and Liliana Gonzáles', numero:2},
    {nombre:'Mesa A. Marc Lien y Javier Villaseñor', name:'Table A. Marc Lien and Javier Villaseñor', numero:3},
    {nombre:'Mesa A. Naomi Miller y Víctor Palacio', name:'Table A. Naomi Miller and Víctor Palacio', numero:4},
    {nombre:'Mesa A. Darío Núñez y Federico Suarez.', name:'Table A. Darío Núñez and Federico Suarez.', numero:5},

    {nombre:'Mesa B. Marc Lien y Javier Villaseñor', name:'Table B. Marc Lien and Javier Villaseñor', numero:6},
    {nombre:'Mesa B. Carrie Hawley y Liliana Garcia', name:'Table B. Carrie Hawley and Liliana Garcia', numero:7},
    {nombre:'Mesa B. Darío Núñez y Federico Suarez', name:'Table B. Darío Núñez and Federico Suarez', numero:8},
    {nombre:'Mesa B. Naomi Miller y Victor Palacio', name:'Rable B. Naomi Miller and Victor Palacio', numero:9},
    {nombre:'Mesa C. Naomi Miller y Victor Palacio', name:'Table C. Naomi Miller and Victor Palacio', numero:10},
    {nombre:'Mesa C. Carríe Hawley y Liliana Garcia', name:'Table C. Carríe Hawley and Liliana Garcia', numero:11},

    {nombre:'Mesa D. Dario Núñez y Federico Suarez', name:'Table D. Dario Núñez and Federico Suarez', numero:12},
    {nombre:'Mesa D. Naomi Miller y Victor Palacio', name:'Table D. Naomi Miller and Victor Palacio', numero:13},
    {nombre:'Mesa D. Marc Lien y Javier Villaseñor', name:'Table D. Marc Lien and Javier Villaseñor', numero:14},
    {nombre:'Mesa D. Carrie Hawley y Liliana Garcia', name:'Table D. Carrie Hawley and Liliana Garcia.', numero:15},
]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      
      <CaretRightFilled className={className} style={{ ...style, display: "block" , color:'#21aaf7', fontSize:'24px'}} onClick={onClick}/>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#21aaf7', fontSize:'24px' }} onClick={onClick}/>
    );
  }

const Conferencia = (props) => {



    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    
    const [estadisticas, setEstadisticas] = useState([]);
    const [mostrar_login, setMostrarLogin] = useState(false);
    const [sin_login, setSinLogin] = useState(false);

    const [ver_dia, setVerDia] = useState(1);
    const [dia, setDia] = useState(1);
    //const [dia, setDia] = useState(1);
    //const [ver_dia, setVerDia] = useState(1);

    const [ver_video, setVerVideo] = useState(1);
    const { idioma } = props;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 4,
            slidesPerRow: 1,
            
          }
        }
      ]
      };


    useEffect(() => {
        if (!usuario) {
            //mensaje('warning','Advertencia','Para entrar a esta sección necesitas haber iniciado sesión.');
            setSinLogin(true);
        } else {
            app.obtener_objetos(nombre_evento + '/configuracion', false, (data) => {
                setDia(data[0].dia);
            });
        }



        let tabla2 = ['carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'peter@paenergyspecialists.com',
        'gipc_15@hotmail.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'jorge.avila@signify.com',
        'vmp@ideasenluz.com.mx',
        'luisalmeidacasino@gmail.com',
        'agarzat@iluminaciontotal.com',
        'agarzat@iluminaciontotal.com',
        'proyectos@nieva-iluminacion.com',
        'pamelaoc8@gmail.com',
        'gipc_15@hotmail.com',
        'alex@wawa.lighting',
        'sergio.sanchez@egea.com.mx',
        'vmp@ideasenluz.com.mx',
        'jaguilar@toljy.com',
        'inf0@elprimerdia.com',
        'csvazquez@lutron.com',
        'marina.lopez.g@signify.com',
        'luis.sanchez@egea.com.mx',
        'ernesto.zamora@egea.com.mx',
        'carrillochataing@gmail.com',
        'andy@thethree.com.mx  ',
        'csvazquez@lutron.com',
        'carrillochataing@gmail.com',
        'victor.gonzalez@edu.uag.mx',
        'carrillochataing@gmail.com',
        'agarzat@iluminaciontotal.com',
        'gipc_15@hotmail.com',
        'maescobedo@unicorplighting.com.mx',
        'inf0@elprimerdia.com',
        'luisjuan@iluminet.com',
        'proyectos@nieva-iluminacion.com',
        'Proyectos@blux.com.mx',
        'csvazquez@lutron.com',
        'inf0@elprimerdia.com',
        'inf0@elprimerdia.com',
        'inf0@elprimerdia.com',
        'mazv@euroelectrica.com.mx',
        'carrillochataing@gmail.com',
        'csvazquez@lutron.com',
        'alex@wawa.lighting',
        'contacto@aplperu.pe',
        'ernesto.zamora@egea.com.mx',
        'marina.lopez.g@signify.com',
        'csvazquez@lutron.com',
        'mfuente@conduktiva.com',
        'mfuente@conduktiva.com',
        'andy@thethree.com.mx',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'luisjuan@iluminet.com',
        'bmusselman@ies.org',
        'carrillochataing@gmail.com',
        'maescobedo@unicorplighting.com.mx',
        'alex@wawa.lighting',
        'rbmconsultor@gmail.com',
        'mazv@euroelectrica.com.mx',
        'inf0@elprimerdia.com',
        'inf0@elprimerdia.com',
        'mfuente@conduktiva.com',
        'luisjuan@iluminet.com',
        'mgongora@avantg.com.mx',
        'carrillochataing@gmail.com',
        'anvargasch@unal.edu.co',
        'ernesto.zamora@egea.com.mx',
        'sakti.kali@gmail.com',
        'lugy@ilumplan.com',
        'carrillochataing@gmail.com',
        'mirtaripol@gmail.com',
        'holalightwave@gmail.com',
        'andy@thethree.com.mx',
        'andy@thethree.com.mx',
        'andy@thethree.com.mx',
        'alex@wawa.lighting',
        'agarzat@iluminaciontotal.com',
        'israel.jauregui@egea.com.mx',
        'lizetmorelos@hotmail.com',
        'mgongora@avantg.com.mx',
        'gabriela.mendez@iesmexico.org',
        'gipc_15@hotmail.com',
        'gipc_15@hotmail.com',
        'andy@thethree.com.mx',
        'rbmconsultor@gmail.com',
        'proyectos1@unicorplighting.com.mx',
        'luca_valdrighi@yahoo.com',
        'omsimonureseroma@gmail.com',
        'andy@thethree.com.mx',
        'andy@thethree.com.mx',
        'mariaaurora.tostado@gmail.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'rbmconsultor@gmail.com',
        'alex@wawa.lighting',
        'holalightwave@gmail.com',
        'Proyectos@blux.com.mx',
        'gabriela.mendez@iesmexico.org',
        'gipc_15@hotmail.com',
        'mariaaurora.tostado@gmail.com',
        'luca_valdrighi@yahoo.com',
        'holalightwave@gmail.com',
        'omsimonureseroma@gmail.com',
        'marina.lopez.g@signify.com',
        'garcia.leonelesteban@gmail.com',
        'Proyectos@blux.com.mx',
        'gerencia@sunleds.mx',
        'mariaaurora.tostado@gmail.com',
        'pau.marin@iluminet.com',
        'gipc_15@hotmail.com',
        'holalightwave@gmail.com',
        'garo_mik13@comunidad.unam.mx',
        'carlosgalan@prodigy.net.mx',
        'holalightwave@gmail.com',
        'lizetmorelos@hotmail.com',
        'jorge.avila@signify.com',
        'lizetmorelos@hotmail.com',
        'ernesto.zamora@egea.com.mx',
        'israel.jauregui@egea.com.mx',
        'agarzat@iluminaciontotal.com',
        'carrillochataing@gmail.com',
        'israel.jauregui@egea.com.mx',
        'vivis.jaklin@gmail.com',
        'rbmconsultor@gmail.com',
        'rbmconsultor@gmail.com',
        'omsimonureseroma@gmail.com',
        'luca_valdrighi@yahoo.com',
        'andy@thethree.com.mx',
        'carlosgalan@prodigy.net.mx',
        'sakti.kali@gmail.com',
        'proyectos1@unicorplighting.com.mx',
        'carlosgalan@prodigy.net.mx',
        'carlosgalan@prodigy.net.mx',
        'carrillochataing@gmail.com',
        'ernesto.zamora@egea.com.mx',
        'proyectos@nieva-iluminacion.com',
        'proyectos@nieva-iluminacion.com',
        'carrillochataing@gmail.com',
        'inf0@elprimerdia.com',
        'jesus.uriarte@edu.uag.mx',
        'jesus.uriarte@edu.uag.mx',
        'agarzat@iluminaciontotal.com',
        'mazv@euroelectrica.com.mx',
        'proyectos@architecturallighting.com.mx',
        'garo_mik13@comunidad.unam.mx',
        'proyectos1@unicorplighting.com.mx',
        'alex@wawa.lighting',
        'holalightwave@gmail.com',
        'gipc_15@hotmail.com',
        'mariaaurora.tostado@gmail.com',
        'proyectos1@unicorplighting.com.mx',
        'carlos@ucetti.com',
        'inf0@elprimerdia.com',
        'adijoecfe@gmail.com',
        'mazv@euroelectrica.com.mx',
        'adijoecfe@gmail.com',
        'inf0@elprimerdia.com',
        'garcia.leonelesteban@gmail.com',
        'omsimonureseroma@gmail.com',
        'gipc_15@hotmail.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'proyectos@nieva-iluminacion.com',
        'luis.sanchez@egea.com.mx',
        'ernesto.zamora@egea.com.mx',
        'alex@wawa.lighting',
        'paulina@wawa.lighting',
        'raul.serrano@iluminet.com',
        'carrillochataing@gmail.com',
        'alex@wawa.lighting',
        'luisjuan@iluminet.com',
        'mgongora@avantg.com.mx',
        'proyectos@nieva-iluminacion.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'raul.serrano@iluminet.com',
        'carrillochataing@gmail.com',
        'luisjuan@iluminet.com',
        'aurora_bo@hotmail.com',
        'lilianagc@italux.com.mx',
        'inf0@elprimerdia.com',
        'omsimonureseroma@gmail.com',
        'gerencia@sunleds.mx',
        'alex@wawa.lighting',
        'alex@wawa.lighting',
        'alex@wawa.lighting',
        'mfuente@conduktiva.com',
        'Proyectos@blux.com.mx',
        'mfuente@conduktiva.com',
        'carrillochataing@gmail.com',
        'ernesto.zamora@egea.com.mx',
        'gipc_15@hotmail.com',
        'garo_mik13@comunidad.unam.mx',
        'mazv@euroelectrica.com.mx',
        'aurora_bo@hotmail.com',
        'carrillochataing@gmail.com',
        'carlos.alberto.garcia.sosa@signify.com',
        'inf0@elprimerdia.com',
        'alex@wawa.lighting',
        'gerencia@sunleds.mx',
        'mfuente@conduktiva.com',
        'garo_mik13@comunidad.unam.mx',
        'gipc_15@hotmail.com',
        'omsimonureseroma@gmail.com',
        'proyectos1@unicorplighting.com.mx',
        'alex@wawa.lighting',
        'holalightwave@gmail.com',
        'proyectos1@unicorplighting.com.mx',
        'mfuente@conduktiva.com',
        'adijoecfe@gmail.com',
        'omsimonureseroma@gmail.com',
        'aurora_bo@hotmail.com',
        'lizetmorelos@hotmail.com',
        'ernesto.zamora@egea.com.mx',
        'mfuente@conduktiva.com',
        'maescobedo@unicorplighting.com.mx',
        'omsimonureseroma@gmail.com',
        'inf0@elprimerdia.com',
        'proyectos1@unicorplighting.com.mx',
        'aurora_bo@hotmail.com',
        'mazv@euroelectrica.com.mx',
        'gabriela.mendez@iesmexico.org',
        'aurora_bo@hotmail.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'carrillochataing@gmail.com',
        'mazv@euroelectrica.com.mx',
        'eca333luxes@gmail.com',
        'mfuente@conduktiva.com',
        'mfuente@conduktiva.com',
        'garo_mik13@comunidad.unam.mx',
        'mazv@euroelectrica.com.mx',
        'omsimonureseroma@gmail.com',
        'hello@palomamendoza.com',
        'omsimonureseroma@gmail.com',
        'agarzat@iluminaciontotal.com',
        'rbmconsultor@gmail.com',
        'lizetmorelos@hotmail.com',
        'omsimonureseroma@gmail.com',
        'alex@wawa.lighting',
        'proyectos@nieva-iluminacion.com',
        'alex@wawa.lighting',
        'omsimonureseroma@gmail.com',
        'ernesto.zamora@egea.com.mx',
        'gabriela.mendez@iesmexico.org',
        'inf0@elprimerdia.com',
        'carrillochataing@gmail.com',
        'proyectos@nieva-iluminacion.com',
        'maescobedo@unicorplighting.com.mx',
        'proyectos@nieva-iluminacion.com',
        'carrillochataing@gmail.com',
        'v.castillocerva1@ledvance.com',
        'mfuente@conduktiva.com',
        'raul.serrano@iluminet.com',
        'ernesto.zamora@egea.com.mx',
        'carrillochataing@gmail.com',
        'garo_mik13@comunidad.unam.mx',
        'PAOLA@DIMEQRO.COM',
        'lulu.nieva@gmail.com',
        'fernandamunguia.746@gmail.com',
        'gipc_15@hotmail.com',
        'mazv@euroelectrica.com.mx',
        'mazv@euroelectrica.com.mx',
        'proyectos1@unicorplighting.com.mx',
        'rbmconsultor@gmail.com',
        'mfuente@conduktiva.com',
        'carol.rodas.ipn@gmail.com',
        'lilianagc@italux.com.mx',
        'lilianagc@italux.com.mx',
        'lilianagc@italux.com.mx',
        'omsimonureseroma@gmail.com',
        'agarzat@iluminaciontotal.com',
        'hello@palomamendoza.com',
        'mariaaurora.tostado@gmail.com',
        'vmp@ideasenluz.com.mx',
        'inf0@elprimerdia.com',
        'inf0@elprimerdia.com',
        'pythic@hotmail.com',
        'carrillochataing@gmail.com',
        'mazv@euroelectrica.com.mx',
        'garo_mik13@comunidad.unam.mx',
        'garo_mik13@comunidad.unam.mx',
        'eca333luxes@gmail.com',
        'rbmconsultor@gmail.com',
        'aisabel120188@gmail.com',
        'hello@palomamendoza.com',
        'carrillochataing@gmail.com',
        'fernandamunguia.746@gmail.com',
        'maescobedo@unicorplighting.com.mx',
        'gipc_15@hotmail.com',
        'mazv@euroelectrica.com.mx',
        'omsimonureseroma@gmail.com',
        'carrillochataing@gmail.com',
        'omsimonureseroma@gmail.com',
        'pau.marin@iluminet.com',
        'vmp@ideasenluz.com.mx',
        'omsimonureseroma@gmail.com',
        'eca333luxes@gmail.com',
        'garo_mik13@comunidad.unam.mx',
        'eca333luxes@gmail.com',
        'alejandra.avilez1906@gmail.com',
        'mgongora@avantg.com.mx',
        'rbmconsultor@gmail.com',
        'proyectos1@unicorplighting.com.mx',
        'gabriela.mendez@iesmexico.org',
        'gabriela.mendez@iesmexico.org',
        'gipc_15@hotmail.com',
        'lulu.nieva@gmail.com',
        'proyectos@nieva-iluminacion.com',
        'omsimonureseroma@gmail.com'];

        var counts = {}; 
        for(var i = 0; i< tabla2.length; i++) 
        { 
            var num = tabla2[i]; 
            counts[num] = counts[num] ? counts[num]+1 : 1; 
        }

        console.log('emntrados');
        console.log(counts);


    }, [usuario]);


    const siguienteEtapa = (usuario_respuestas) => {

        key = usuario_respuestas.key;

        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respondio;
                app.obtener_objetos(nombre_evento + '/preguntas-vivo', false, (datos) => {
                    setPreguntas(datos.filter(pregunta => { return pregunta.dia === dia }));
                    app.obtener_objetos(nombre_evento + `/usuarios-encuesta${dia}`, true, (datos) => {
                        setEstadisticas(datos);
                        setCargando(false);
                        if (typeof existe_respuestas !== 'undefined') {
                            setEtapa(2);
                        } else {
                            setEtapa(1);
                        }
                    });
                })

                break;
            case 1:
                app.obtener_objetos(nombre_evento + `/usuarios-encuesta${dia}`, true, (datos) => {
                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
                break;
        }
    }

    const guardarRespuestas = (respuestas) => {
        console.log(datos);

        const actualizar_usuario = {
            correo: datos.correo,
            nombre: datos.nombre + ' ' + datos.ap_paterno + ' ' + datos.ap_materno,
            empresa: datos.empresa,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + `/usuarios-encuesta${dia}`, key, actualizar_usuario, siguienteEtapa);

    }

    useEffect(() => {
        if(usuario){
            app.obtener_objeto(nombre_evento + `/usuarios-encuesta${dia}`, 'correo', datos.correo, siguienteEtapa);
            app.obtener_objetos(nombre_evento + `/preguntas-publico${dia}`, true, (data) => {
                console.log(data);
                setPreguntasPonente(data);
            });
        }
    }, [dia]);

    let ver_conferencias = lives.filter(dia =>{
        if(dia.dia == ver_dia && dia.video == ver_video){
          return true;
        }
        return false;
     });
    let ver_confes = ver_conferencias.map((objetos,index)=>
   { return(
    <Col span={24} >
        
        <Live2 url={objetos.url} url_eng={objetos.url_ing} urlyt={null} urlfb={null} urlln={null} webinar={nombre_evento} comentarios={preguntas_ponente} dia={dia} idioma={idioma} num_conferencia={objetos.video} activar_preguntas={objetos.activar_preguntas}/>
    </Col>
    )
})

let ver_mesas = mesas.map((objetos,index)=>
    { 
        return(
            <Col className={ver_video === objetos.numero ? 'video_envivo_select' : 'video_envivo'} onClick={() => {setVerVideo(objetos.numero)}}>
                <p className="sin_margin texto_video" style={{paddingLeft:'5px', paddingRight:'0px'}} >
                    {idioma==='es'? objetos.nombre : objetos.name}
                </p>
            </Col>
        )
    })

    return (

        <>

            <div className="cuerpo">

                <Row align="">
                    <Col xs={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }} >
                        <br /><br /><br /><br /><br />
                    </Col>
                    <Col xs={{ span: 20, offset: 1 }} md={{ span: 0, offset: 0 }} >
                        <br /><br /><br />
                    </Col>
                    <Col xs={{ span: 4, offset: 2 }} md={{ span: 4, offset: 2 }} onClick={() => {setVerDia(1); setVerVideo(1);}}>
                        <p className={ver_dia === 1 ? 'dia_envivo_select' : 'dia_envivo'}>
                        {idioma==='es'? 
                            <>
                                19 DE ABRIL
                            </>
                            : 
                            <>
                                APRIL <br className="desaparecer" />19 
                            </>
                        }
                        </p>
                    </Col>
                    <Col xs={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} onClick={() => {setVerDia(2); setVerVideo(1);}}>
                        <p className={ver_dia === 2 ? 'dia_envivo_select' : 'dia_envivo'}>
                        {idioma==='es'? 
                            <>
                                20 DE ABRIL
                            </>
                            : 
                            <>
                                APRIL <br className="desaparecer" />20
                            </>
                        }
                        
                        </p>
                    </Col>
                    <Col xs={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} onClick={() => {setVerDia(3); setVerVideo(1);}}>
                        <p className={ver_dia === 3 ? 'dia_envivo_select' : 'dia_envivo'}>
                        {idioma==='es'? 
                            <>
                                21 DE ABRIL
                            </>
                            : 
                            <>
                                APRIL <br className="desaparecer" />21
                            </>
                        }
                        </p>
                    </Col>
                    <Col xs={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} onClick={() => {setVerDia(4); setVerVideo(1);}}>
                        <p className={ver_dia === 4 ? 'dia_envivo_select' : 'dia_envivo'}>
                        {idioma==='es'? 
                            <>
                                22 DE ABRIL
                            </>
                            : 
                            <>
                                APRIL <br className="desaparecer" />22
                            </>
                        }
                        </p>
                    </Col>
                    <Col xs={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} onClick={() => {setVerDia(5); setVerVideo(1);}}>
                        <p className={ver_dia === 5 ? 'dia_envivo_select' : 'dia_envivo'}>
                        {idioma==='es'? 
                            <>
                                23 DE ABRIL
                            </>
                            : 
                            <>
                                APRIL <br className="desaparecer" />23
                            </>
                        }
                        </p>
                    </Col>
                    {usuario ?
                        <>
                            {
                                ver_dia === 1?
                                <Col span={22} offset={1}>
                                    <Row align="" justify="center">
                                        <Col className={ver_video === 1 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={6} onClick={() => {setVerVideo(1)}}>
                                            <p className="sin_margin padding_top texto_video">
                                                {idioma==='es'? 'Bienvenida' : 'Welcome'}
                                                </p>
                                        </Col>
                                        <Col className={ver_video === 2 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={6} onClick={() => {setVerVideo(2)}}>
                                            <p className="sin_margin texto_video">
                                                {idioma==='es'? 'Las mutaciones de la iluminación urbana' : 'The mutations of urban lighting'}
                                                </p>
                                        </Col>
                                        <Col className={ver_video === 3 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={6} onClick={() => {setVerVideo(3)}}>
                                            <p className="sin_margin texto_video">
                                                {idioma==='es'? 'Iluminación Social: Del discurso a la praxis' : 'Social Enlightenment: From discourse to praxis'}
                                                </p>
                                        </Col>
                                        <Col className={ver_video === 4 ? 'video_envivo_select' : 'video_envivo no_select_border'} xs={20} md={6} onClick={() => {setVerVideo(4)}}>
                                            <p className="sin_margin padding_top texto_video">
                                                {idioma==='es'? 'Fuerzas de cambio 2021' : 'Forces of change 2021'}
                                                </p>
                                        </Col>
                                    </Row>
                            </Col>
                            : ver_dia === 2 ?
                            <Col span={22} offset={1}>
                                    <Row align="" justify="center">
                                        <Col className={ver_video === 1 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={8} onClick={() => {setVerVideo(1)}}>
                                            <p className="sin_margin texto_video">
                                                {idioma==='es'? 'Nuevos enfoques para la práctica de diseño de iluminación' : 'New Approaches to Lighting Design Practice'}
                                                </p>
                                        </Col>
                                        <Col className={ver_video === 2 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={8} onClick={() => {setVerVideo(2)}}>
                                            <p className="sin_margin texto_video">
                                                {idioma==='es'? 'Cronobiología y calidad de la luz: Oportunidades para una iluminación integrativa' : 'Chronobiology and quality of light: opportunities for integrative lighting'}
                                                </p>
                                        </Col>
                                        <Col className={ver_video === 3 ? 'video_envivo_select' : 'video_envivo no_select_border'} xs={20} md={8} onClick={() => {setVerVideo(3)}}>
                                            <p className="sin_margin texto_video">
                                                {idioma==='es'? 'Luz y símbolos de luz en China: Una mirada desde oriente' : 'Light and symbols of light in China: A look from the East'}
                                                </p>
                                        </Col>
                                    </Row>
                            </Col>
                            :
                            ver_dia === 3 ?
                            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                                
                                <Slider {...settings}>
                                        {ver_mesas}
                                    </Slider>
                                    <br />
                            </Col>
                            :
                            ver_dia === 4 ?
                            <Col span={22} offset={1}>
                                    <Row align="" justify="center">
                                        <Col className={ver_video === 1 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={8} onClick={() => {setVerVideo(1)}}>
                                            <p className="sin_margin texto_video">
                                                {idioma==='es'? 'La práctica profesional y la importancia de hacer comunidad' : 'Professional practice and the importance of building community'}
                                                </p>
                                        </Col>
                                        <Col className={ver_video === 2 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={8} onClick={() => {setVerVideo(2)}}>
                                            <p className="sin_margin texto_video">
                                                {idioma==='es'? 'Contexto del alumbrado público en México y su impacto en la evolución del consumo de energía eléctrica' : 'Context of public lighting in Mexico and its impact on the evolution of electrical energy consumption'}
                                                </p>
                                        </Col>
                                        <Col className={ver_video === 3 ? 'video_envivo_select' : 'video_envivo no_select_border'} xs={20} md={8} onClick={() => {setVerVideo(3)}}>
                                            <p className="sin_margin texto_video padding_top">
                                                {idioma==='es'? 'Iluminación para personas en entornos al aire libre' : 'Lighting for People in Outdoor Environments'}
                                                </p>
                                        </Col>
                                    </Row>
                            </Col>
                            :
                            <Col span={22} offset={1}>
                            <Row align="" justify="center">
                                <Col className={ver_video === 1 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={6} onClick={() => {setVerVideo(1)}}>
                                    <p className="sin_margin texto_video">
                                        {idioma==='es'? 'Hablemos del paisaje nocturno: La noche nórdica' : "Let's talk about the nightscape: The Nordic night"}
                                        </p>
                                </Col>
                                <Col className={ver_video === 2 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={6} onClick={() => {setVerVideo(2)}}>
                                    <p className="sin_margin texto_video">
                                        {idioma==='es'? 'Resaltando la Coordinación: Un Parque Interactivo en Detroit' : 'Shining Light on Coordination: An Interactive Park in Detroit'}
                                        </p>
                                </Col>
                                <Col className={ver_video === 3 ? 'video_envivo_select' : 'video_envivo'} xs={20} md={6} onClick={() => {setVerVideo(3)}}>
                                    <p className="sin_margin texto_video padding_top ">
                                        {idioma==='es'? 'La magia de la luz como material' : 'The magic of light as a material'}
                                        </p>
                                </Col>
                                <Col className={ver_video === 4 ? 'video_envivo_select' : 'video_envivo no_select_border'} xs={20} md={6} onClick={() => {setVerVideo(4)}}>
                                    <p className="sin_margin texto_video padding_top ">
                                        {idioma==='es'? 'Clausura' : 'Closure'}
                                        </p>
                                </Col>
                            </Row>
                    </Col>
                            }

<Col span={24}>
                <br/>
            </Col>
                    {ver_confes}
            <Col span={24}>
                <br/>
            </Col>


                            <Col span={24}>
                                <br />
                            </Col>

                            <Col span={24} style={{ display: 'none' }}>
                                <Spin tip="Cargando..." spinning={cargando} >
                                    {
                                        etapa === 1 ?
                                            (
                                                <>
                                                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} className="">
                                                        <p className="textos_titulos_secciones_no_olvides Antonio-Bold color_azul">
                                                            {idioma === 'es' ?
                                                                <>PARTICIPA EN  NUESTRA ENCUESTA</>
                                                                :
                                                                <>PARTICIPATE IN OUR SURVEY</>
                                                            }</p>
                                                    </Col>
                                                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} >
                                                        {<Preguntas dia={dia} preguntas={preguntas} onResponse={guardarRespuestas} idioma={idioma} />}
                                                    </Col>

                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} className="" style={{display:'non'}}>
                                                        <p className="textos_titulos_secciones_no_olvides color_azul">
                                                            {idioma === 'es' ?
                                                                <>GRACIAS POR PARTICIPAR</>
                                                                :
                                                                <>THANKS FOR PARTICIPATING</>
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} style={{display:'none'}} >
                                                        {<Estadisticas estadisticas={estadisticas} preguntas={preguntas} idioma={idioma} />}
                                                    </Col>
                                                </>
                                            )
                                    }
                                </Spin>
                                <br /><br />
                            </Col>
                            <Col span={0} className="contenedor_comparte">
                                <p className="text_azul_comparte">Compártenos tu experiencia utilizando</p>
                            </Col>
                            {datos.mesa_directiva === 1 &&
                            <Col span={22} offset={1} style={{display:'none'}}>
                                <Row justify="center">
                                    <Col span={24}>
                                        <p className="color_azul" style={{fontSize:'40px', marginBottom:'0px'}}>
                                            MESAS CRUZADAS
                                        </p>    
                                    </Col>

                                
                                {ponentes.filter((ponente,index) => {
                                    if(idioma === 'es'){    
                                        if(index < 4){
                                            return true;
                                        }
                                    }else{
                                        if(index >= 4){
                                            return true;
                                        }
                                    }
                                return false;}).map(ponente => {
                                    return(
                                        <Col span={5}>
                                            <a target="_blank" href="https://us02web.zoom.us/j/84613249990?pwd=U0gyOHFONDFpa3BzK1N6SEVxNFo4dz09 ">
                                                <img src={ponente} alt="mesa_cruzada" style={{width:'100%'}} />
                                            </a>
                                        </Col>
                                    )
                                })
                                }
                            
                            </Row>
                            <br /><br />
                            </Col>
}
                        </>
                        :
                        <Col span={24} className="contendor_proximamente">
                            <br /><br /><br />
                            <br />
                            <p style={{color:'white', fontSize:'25px', fontWeight:'bold'}}>
                                {idioma === 'es' ? 'Por favor inicia sesión para poder acceder a este contenido.' : 'Please log in to access this content.'}
                            </p>
                            <br /><br />
                            <br /><br /><br />
                        </Col>
                    }
                    

                </Row>

            </div>

            

        </>

    );

}

export default Conferencia;

