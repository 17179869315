import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import '../../assets/css/ponentes.css';

import ponente1 from '../../assets/images/2023/ponentes/ies_CeciliaRamos.png';
import ponente2 from '../../assets/images/2023/ponentes/ies_ChiaraCarucci.png';
import ponente3 from '../../assets/images/2023/ponentes/ies_DanielaViloria.png';
import ponente4 from '../../assets/images/2023/ponentes/ies_FrankAgraz.png';
import ponente5 from '../../assets/images/2023/ponentes/ies_KatyaKolovea.png';
import ponente6 from '../../assets/images/2023/ponentes/ies_NickAlbert.png';
import ponente7 from '../../assets/images/2023/ponentes/ies_PaulinaVillaloboz.png';
import ponente8 from '../../assets/images/2023/ponentes/ies_TonyEsposito.png';
import ponente9 from '../../assets/images/2023/ponentes/Pedro_Garza.png';

import flag_french from '../../assets/images/2023/banderas/ies_Francia.png';
import flag_greak from '../../assets/images/2023/banderas/ies_Grecia.png';
import flag_india from '../../assets/images/2023/banderas/ies_India.png';
import flag_mex from '../../assets/images/2023/banderas/ies_Mexico.png';
import flag_peru from '../../assets/images/2023/banderas/ies_Peru.png';
import flag_suecia from '../../assets/images/2023/banderas/ies_Suecia.png';
import flag_usa from '../../assets/images/2023/banderas/ies_Usa.png';
import flag_ingla from '../../assets/images/2023/banderas/ies_Inglaterra.png';
import flag_chile from '../../assets/images/2023/banderas/ies_Chile.png';
import flag_espa from '../../assets/images/2023/banderas/ies_Espania.png';
import flag_veneca from '../../assets/images/2023/banderas/ies_Venezuela.png';
import flag_ita from '../../assets/images/2023/banderas/ies_Italia.png';


import titulo_conferencista from '../../assets/images/2023/titulos/ies_conferencistas.png';
import titulo_conferencista_en from '../../assets/images/2023/titulos/Titulos_ENG/ies_conferencistas_ENG.png';

import flecha_derecha from '../../assets/images/01_impacDemo_home/02 boton-derecha.png';
import flecha_izquierda from '../../assets/images/01_impacDemo_home/02 boton-izquierda.png';


const logos = [
    { index:1, img_src: ponente1, nombre: 'Cecilia', apellido: 'E. Ramos ',
    descripcion:[<>Cecilia E. Ramos es director sénior del mercado arquitectónico de Lutron Electronics. Los antecedentes y la experiencia de Cecilia son en diseño de iluminación, diseño de interiores y arquitectura,  le apasiona la intersección de la tecnología, el arte y el diseño.<br /><br />
      Cecilia recibió su títulos en arquitectura del Instituto de Tecnología de Massachusetts (BSAD) y la Universidad de Princeton (Maestría en Arquitectura).<br /><br />
      Antes de unirse a Lutron, viajó por el mundo diseñando la iluminación de marcas minoristas de lujo como Louis Vuitton, Christian Dior y Hublot como directora de la galardonada firma de diseño de iluminación L'Observatoire International. Y antes, como directora de su propia práctica de consultoría de diseño, trabajó con clientes de renombre como el Museo Guggenheim, el Museo de Arte de la Universidad de Princeton y MacroSea.<br /><br />
      En su cargo actual, Cecilia es responsable de liderar la experiencia de Lutron en la estrategia de arquitectura y diseño. El trabajo de ella y su equipo abarca marketing, diseño de productos, diseño experiencial y estrategia de ventas. También desempeñó un papel principal en el diseño y la construcción del galardonado Lutron New York Global Experience Center, la sede de Ketra en Austin, TX y los eventos PRISMATIC 2022 en toda Europa.<br /><br />
      Es coautora del libro “Architectural Lighting, Designing with Light and Space” (Princeton Architectural Press, 2011).
      </>], 
    descripcion_en: [<>Cecilia E. Ramos is the Sr. Director – Architectural Market for Lutron Electronics. Cecilia’s background and expertise is in lighting design, interior design, and architecture, and she has a passion for the intersection of technology, art, and design.<br /><br />
      Cecilia received her degrees in architecture from the Massachusetts Institute of Technology (BSAD) and Princeton University (Masters of Architecture). <br /><br />
      Before joining Lutron, she traveled the world designing the lighting of luxury retail brands such as Louis Vuitton, Christian Dior and Hublot as a Director with award winning lighting design firm L’Observatoire International. And prior, as principal of her own design consulting practice, she worked with renown clients such as Guggenheim Museum, Princeton University Art Museum, and MacroSea.<br /><br />
      In her current role, Cecilia is responsible for leading Lutron’s expertise in the architecture and design strategy. She and her team’s work encompass marketing, product design, experiential design, and sales strategy. She also played a lead role in the design and construction of the award-winning Lutron New York Global Experience Center, Ketra Headquarters in Austin, TX., and 2022 PRISMATIC events throughout Europe.<br /><br />
      She is co-author of the book “Architectural Lighting, Designing with Light and Space” (Princeton Architectural Press, 2011).
      </>],
    bandera:flag_usa,
    bandera2:'',
      previu:[<>Architectural Market, LUTRON</>],
      previu_en:[<>Architectural Market, LUTRON</>]},

    { index:2, img_src: ponente2, nombre: 'Chiara', apellido: 'Carucci ',
    descripcion:[<>Chiara Carucci, asociada de IALD, es una diseñadora de iluminación galardonada con un enfoque en el ámbito público, sitios culturales y naturales. Su visión es proteger el patrimonio mientras se mira al futuro.<br /><br />
      Como profesional independiente, Chiara colabora con equipos multidisciplinarios en proyectos y concursos, especialmente donde están en juego la conservación y la biodiversidad.<br /><br />
      Chiara compagina su actividad profesional con el liderazgo intelectual, otorgado por Women in Lighting en 2021, y la defensa del Diseño de Iluminación. Actualmente es miembro del Comité Directivo del LIRC.
      </>], 
    descripcion_en: [<>Chiara Carucci, IALD Associate, is an award-winning Lighting Designer with a focus on public realm, cultural and natural sites. Her vision is to Protect the Heritage While Looking at the Future.<br /><br />
      As an independent professional, Chiara collaborates with multidisciplinary teams in projects and competitions, especially where conservation and biodiversity are at issue.<br /><br />
      Chiara combines her professional activity with thought leadership, as awarded by Women in Lighting in 2021, and advocacy for the Lighting Design. She currently serves in the Steering Committee of the LIRC.
      </>],
    bandera:flag_ita,
    bandera2:'',
    previu:[<>Chiara Carucci Lighting Design</>],
    previu_en:[<>Chiara Carucci Lighting Design</>]},

    { index:3, img_src: ponente3, nombre: 'Daniela', apellido: 'Viloria García',
    descripcion:[<>Asentada en España desde hace 14 años, ha pasado por todos los eslabones de la cadena de la industria de la iluminación, desde fabricantes de primeras marcas, consultoría, distribución hasta responsable de iluminación para arquitecturas internacionales. Ha llevado su trabajo a decenas de países en numerosos sectores como el retail, hospitality, residencial y urbanismo, entre otros.<br /><br />
      Actualmente dirige su propio proyecto profesional: Dany Viloria Design Consulting.<br /><br />
      Su compromiso con la divulgación de la Cultura de la Luz le llevó en 2019 iniciar su faceta académica colaborando como profesora regular en numerosas universidades y dando formaciones a empresas. Es ponente invitada en múltiples eventos del sector.<br /><br />
      Es Miembro Directivo de la Asociación Profesional de Diseñadores de Iluminación (APDI). En 2021 fue ganadora del 40 UNDER 40 del Lighting Design.</>], 
    descripcion_en: [<>Settled in Spain for 14 years, it has gone through all the links in the lighting industry chain, from leading brand manufacturers, consultancy, distribution to lighting manager for international architectures. She has taken her work to dozens of countries in numerous sectors such as retail, hospitality, residential and urban planning, among others.<br /><br />
      She currently runs her own professional project: Dany Viloria Design Consulting.<br /><br />
      Her commitment to the dissemination of the Culture of Light led her to start her academic side in 2019, collaborating as a regular professor at numerous universities and giving training to companies. She is an invited speaker at multiple industry events.<br /><br />
      She is a Board Member of the Professional Association of Lighting Designers (APDI). In 2021 she was the winner of the 40 UNDER 40 of Lighting Design.</>],
    bandera:flag_veneca,
    bandera2:flag_espa,
    previu:[<>Dany Viloria Design Consulting</>],
    previu_en:[<>Dany Viloria Design Consulting</>] },

    { index:4, img_src: ponente4, nombre: 'Frank', apellido: 'Agraz',
    descripcion:[<>Frank Agraz ha trabajado en la comunidad de modernización de iluminación eficiente en energía durante más de 25 años y se desempeña como Director de Eficiencia Energética en Eco Engineering. Él y su equipo desarrollan proyectos de iluminación   brindando soporte técnico y educativo al cliente. Antes de su puesto actual, cofundó Maneri-Agraz Enterprises, un proveedor nacional de modernización de iluminación. Se desempeña como presidente de la Illuminating Engineering Society<br /><br />
      Agraz está certificado en iluminación por el NCQLP y es el anterior presidente del comité de exámenes, responsable del grupo que desarrolla y mantiene el contenido del examen LC anual. Se graduó de la Universidad de Texas A&M con una licenciatura en Distribución Industrial.</>], 
    descripcion_en: [<>Frank Agraz has worked in the energy efficient lighting retrofit community for more than 25 years and serves as Director of Energy Efficiency at Eco Engineering.  He and his team develop turnkey lighting projects and provide technical and educational support to the customer.  Prior to his current role, he co-founded Maneri-Agraz Enterprises, a national turnkey lighting retrofit provider.  He serves as President of the Illuminating Engineering Society.  Mr. Agraz is Lighting Certified by the NCQLP and is the immediate past Exam Committee Chair, responsible for the group that develops and maintains the content of the annual LC exam.  He graduated from Texas A&M University with a BS in Industrial Distribution.</>],
    bandera:flag_usa,
    bandera2:'',
    previu:[<>Eco Engineering, Inc.</>],
    previu_en:[<>Eco Engineering, Inc.</>] },

    { index:5, img_src: ponente5, nombre: 'Katia', apellido: 'Kolovea ',
    descripcion:[<>Katia Kolovea es diseñadora de iluminación, estratega de comunicación y defensora de la profesión del diseño de iluminación.<br /><br />
      Su pasión por la luz, las personas y la creación de experiencias atractivas y envolventes la llevaron a iniciar su negocio Archifos.<br /><br />
      Ha estado involucrada en una variedad de proyectos internacionales, desde iluminación arquitectónica hasta iluminación de eventos, diseño de iluminación de escenarios, fiestas privadas, presentaciones de marcas de alta gama, iluminación de productos e instalaciones de arte público, por mencionar algunos.<br /><br />
      Cuando no está diseñando experiencias de iluminación interactiva y envolvente, Katia colabora con empresas de la industria de la iluminación para ayudarlas a comunicar su marca y servicios de una manera significativa, innovadora y personalizada, en línea y fuera de línea.<br /><br />
      Su trabajo como diseñadora ha sido galardonado con un "Premio Darc" y fue honrada con el premio de iluminación 40 under 40 en 2019. Katia ha estado involucrada en varios proyectos educativos y comunitarios de la industria de la iluminación como los Premios Silhouette y VLD.Community.<br /><br />
      Le encanta contar historias, hablar en público y reunir a las personas adecuadas. Es la fundadora de Lighting Police, una plataforma educativa con la misión de crear conciencia sobre la importancia de la luz adecuada para el público en general. Es miembro del equipo de la comunidad global de mujeres en la iluminación que administra todas las comunicaciones en las redes sociales, jueza de los premios de diseño LIT y miembro electo del consejo de la Society of Light and Lighting.
      </>], 
    descripcion_en: [<>Katia Kolovea is a lighting designer, communication strategist, and advocate of the lighting design profession. Her passion for light, people, and the creation of engaging and immersing experiences led her to start her business Archifos. She has been involved in a variety of international projects from architectural lighting, to event lighting to stage lighting design, private parties, high-end brand presentations, product lighting, and public art installations to mention a few.<br /><br />
      When she is not designing interactive and immersive lighting experiences, Katia collaborates with businesses in the lighting industry to support them communicate their brand and services in a meaningful, innovative, and personalised way, online and offline.<br /><br />
      Her work as a designer has been awarded a "Darc Award" and was honoured with the 40under40 lighting award in 2019. Katia has been involved in various community-based and educational projects of the lighting industry like the Silhouette Awards and the VLD.Community. She loves storytelling, public speaking, and bringing the right people together. Katia is the founder of the Lighting Police, an educational platform with the mission to raise awareness about the importance of the right light to the general public. She is a team member of the global Women in Lighting Community managing all social media communications, a Judge of the LIT design awards, and an elected council member of the Society of Light and Lighting.
      </>],
    bandera:flag_greak,
    bandera2:'',
    previu:[<>Archifos</>],
    previu_en:[<>Archifos</>] },

    { index:6, img_src: ponente6, nombre: 'Nick', apellido: 'Albert',
    descripcion:[<>Nick Albert es director fundador de Chromatic, un estudio de iluminación y laboratorio de investigación basado en acciones ubicado en Los Ángeles. Es un veterano de veinte años en las industrias de iluminación, arquitectura y construcción con una cartera de cientos de obras construidas en todo el mundo con premios en las comunidades de iluminación de EE. UU. e internacionales. Nick fue anteriormente el Director de Diseño de Iluminación de EE. UU. para Hirsch Bedner Associates, Inc, la firma de diseño de hotelería más grande del mundo que lidera equipos en múltiples oficinas para proyectos en todos los segmentos del mercado. Nick ha enseñado diseño de iluminación en Otis College of Art and Design, UCLA Extension, ha dado conferencias como invitado en la Universidad del Sur de California, es orador público y ha publicado numerosos artículos sobre iluminación y diseño de iluminación y es miembro de varios consejos asesores.</>], 
    descripcion_en: [<>Nick Albert is a founding Principal of Chromatic, an equity based lighting studio and research lab located in Los Angeles. He is a  twenty-year veteran of the lighting, architecture and construction industries with a portfolio of hundreds of built works across the globe with awards in both the US and International Lighting communities. Nick was previously the US Director of Lighting Design for Hirsch Bedner Associates, Inc,  the world’s largest hospitality design firm leading teams across multiple offices for projects across all market segments. Nick has taught lighting design at Otis College of Art and Design, UCLA Extension, guest lectures at the University of Southern California, is a public speaker and has published numerous articles on light and lighting design and serves on various advisory boards.</>],
    bandera:flag_usa,
    bandera2:'',
    previu:[<>Chromatic, Inc.</>],
    previu_en:[<>Chromatic, Inc.</>] },

    { index:7, img_src: ponente7, nombre: 'Paulina', apellido: 'Villalobos',
    descripcion:[<>Directora DIAV Lighting. Oficina dedicada al Diseño de Iluminación Arquitectónica.<br /><br />
      Fundadora de Noche Zero, iniciativa para mejorar el futuro de la iluminación de las ciudades.<br /><br />
      Arquitecta de la Universidad de Chile<br /><br />
      Diplomada en Conectividad Urbana, UNCRD, Nagoya, Japón<br /><br />
      Máster de Iluminación Arquitectónica de la Universidad Tecnológica de Wismar, Alemania.<br /><br />
      Diseñadora de Iluminación de la Universidad Tecnológica Real, KTH, Suecia.<br /><br />
      Sus trabajos en diseño de iluminación arquitectónica e instalaciones artísticas de luz han sido premiados en Europa, Asia y América.<br /><br />
Ha sido docente en Universidades de Chile, Suecia, Finlandia, USA y España.<br /><br />
El 2018 recibió el Premio Dora Riedel, otorgado por primera vez por el Colegio de Arquitectos de Chile, como reconocimiento a la innovación abriendo nuevos caminos.<br /><br />
Miembro del Directorio de la International Darksky Association, USA.<br /><br />
Core Member Tanteidan, International Lighting Detectives, Japón.<br /><br />
Ciluz, Chile.<br /><br />
Embajadora WIL para Chile y Miembro VLDC<br /><br />
</>], 
    descripcion_en: [<>Paulina hails from the Atacama Desert in Chile–the driest, brightest, and starriest place on Earth. In 2005 she founded DIAV, an Architectural Lighting Design practice based in Santiago, Chile, and developed Conceptual Design for Singapore. Her lighting design work has been awarded in Europe, Asia, and America. In 2012 Paulina founded Noche Zero, an initiative to change the paradigm for urban lighting planning, promoting the importance of lighting design and incorporating ecology, human health, and light pollution control. Noche Zero received the IDA Dark Sky Defender Award in 2012 and was nominated for the Award at large in PLDC Copenhagen.<br /><br />
      She is currently a lighting design professor for post-graduate programs at the University of Chile and is recognized as an enthusiastic speaker. She has spoken at many conferences, classes, and seminars worldwide. Additionally, Paulina is the editor of the lighting chapter for the guide of sustainable public spaces for Chile (Chilean Chamber of Construction, Ministry of Urban Planning), a consultant for the new regulation of interior lighting in Chile, and current consultant for regulatory review of outdoor lighting in Chile (Ministry of Energy).<br /><br />
      </>],
    bandera:flag_chile,
    bandera2:'',
    previu:[<>DIAV Architectural Lighting Design</>],
    previu_en:[<>DIAV Architectural Lighting Design</>] },

    { index:8, img_src: ponente8, nombre: 'Tony', apellido: 'Esposito',
    descripcion:[<>Tony es un investigador de calidad de iluminación con experiencia en diseño e ingeniería de iluminación. Sus especialidades incluyen ingeniería de iluminación, ciencia del color, IES TM-30, factores humanos, diseño de iluminación, estadísticas, diseño experimental, modelado espectral, métricas circadianas, redacción técnica y comunicación, y desarrollo de Excel. Es el fundador y director científico de investigación de Lighting Research Solutions LLC, una consultoría de iluminación que brinda servicios de investigación, educación y desarrollo. Actualmente es copresidente del Comité de Color de la Illuminating Engineering Society.</>], 
    descripcion_en: [<>Tony is a lighting quality researcher with a background in lighting design and engineering. His specialties include illumination engineering, color science, IES TM-30, human factors, lighting design, statistics, experimental design, spectral modeling, circadian metrics, technical writing and communication, and Excel development. He is the Founder and Head Research Scientist at Lighting Research Solutions LLC, a lighting consultancy providing services in research, education, and development. He is currently the Co-Chair of the Color Committee of the Illuminating Engineering Society.</>],
    bandera:flag_usa,
    bandera2:'',
    previu:[<>Lighting Researcher Solutions</>],
    previu_en:[<>Lighting Researcher Solutions</>] },

    { index:9, img_src: ponente9, nombre: 'Pedro', apellido: 'Garza de Yta',
    descripcion:[<>EXPERIENCIA
      DIRECTOR GENERAL<br /><br />
      (1997-Actualidad)<br /><br />
      Director General y Fundador de Mantenimiento Arquitectónico Integral S.A de C.V. Empresa dedicada al desarrollo de ingenierías e implementación de proyectos especializados en el mercado comercial y residencial.<br /><br />
      LOGROS Y EXTRACURRICULARES<br /><br />
      En 2018 el Arq. Pedro Garza, fue galardonado por parte de Artesanías Mexicanas en el palacio de Bellas Artes, recibiendo un reconocido homenaje.
      <br /><br />
      Se ha distinguido principalmente por obras en el ramo de la iluminación, entre ellas enumeramos, las Catedrales de Morelia, Guanajuato, Antigua Basílica de Guadalupe, las Calles de Regina, las ruinas de Xochicalco, MIDE) Museo Interactivo de Economía.
       <br /><br />
      Al iluminar las Torres del Bicentenario de Toluca, participó en la Estela de Luz y en otros grandes proyectos.
       <br /><br />
      Miembro del CAM SAM Colegio de Arquitectos de la Ciudad de México desde 1964.
      Architectural Digest, otorga diploma por ser ganador en la Categoría de Iluminación en la primera entrega de Premios - Iconos del Diseño 2005
       <br /><br />
      Por su trabajo de vanguardia en iluminación realizado en el Templo de la Asunción de Dolores, Hidalgo, Mantenimiento Arquitectónico Integral (MAI) obtuvo el Premio al Mérito en el International Illumination Design Award 2007, otorgado por la Illuminating Engineering Society of North America (IESNA)</>], 
    descripcion_en: [<>
    (1997-Present) General Director and Founder of Mantenimiento Arquitectónico Integral S.A de C.V. Company dedicated to the development of engineering and implementation of specialized projects in the commercial and residential market. <br /><br />
      In 2018 Arq. Pedro Garza, was awarded by Artesanías Mexicanas at the Palace of Fine Arts, receiving a recognized tribute. It has been distinguished mainly by works in the field of lighting, among them we find the Cathedrals of Morelia and Guanajuato, Old Basilica of Guadalupe, Regina Street, Ruins of Xochicalco, Interactive Museum of Economy (MIDE). By illuminating the Bicentennial Towers of Toluca, he participated in the Estela de Luz and other large projects.  <br /><br />
      Member of CAM SAM College of Architects of Mexico City since 1964. <br /><br />
      Architectural Digest, awards diploma for being winner in the Lighting Category in the first awards ceremony - Design Icons 2005  <br /><br />
      For his avant-garde work in lighting carried out in the Temple of the Assumption of Dolores, Hidalgo, Mantenimiento Arquitectónico Integral (MAI) obtained the Award of Merit in the International Illumination Design Award 2007, granted by the Illuminating Engineering Society of North America (IESNA).</>],
    bandera:flag_mex,
    bandera2:'',
    previu:[<>Mantenimiento Arquitectonico Integral (MAI)</>],
    previu_en:[<>Mantenimiento Arquitectonico Integral (MAI)</>] },

    

    
];




const CarouselPonentes = (props) => {

  const ref_carousel = React.createRef();
  const [activar_carouse, setActivaCarosel] = useState(0);
  const {idioma} = props;
  const [select_persona, setSelectPersona] = useState(0);
  const [ver_modal, setVerModal] = useState(false);

    let div_logos = logos.map((persona,index) => {
      return(
        <>
        <Col xs={0} lg={24} className="">
          <Row align="middle">
            <Col span='11' offset='2'>
              <p className="nombre_ponente">{persona.nombre}<br />
              <strong>{persona.apellido}</strong></p>
              
            {idioma === 'es' ?
            <p className="bio_ponente">
            {persona.descripcion.map((parrafo,index) => {
              if(index === 0){
                return(
                <>{parrafo}</>
                );
              }else{
                return(
                <><br /><br />{parrafo}</>
                );
              }
              })}
            </p>
            :
            <p className="bio_ponente">
            {persona.descripcion_en.map((parrafo,index) => {
              if(index === 0){
                return(
                <>{parrafo}</>
                );
              }else{
                return(
                <><br /><br />{parrafo}</>
                );
              }
              })}
            </p>

            }
            </Col>
            <Col span={8} offset={1}>
            <img src={persona.img_src} alt={persona.nombre} style={{ width:'100%'}}  />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={0} className="contenedor_ponente_movil">
        <Col span={16} offset={4}>
            <img src={persona.img_src} alt={persona.nombre} style={{ width:'100%'}}  />
            </Col>
            <Col span={20} offset={2}>
              <p className="nombre_ponente_movil">
              <strong>{persona.nombre} {persona.apellido}</strong></p>
              
            <p className="bio_ponente_movil">
            {idioma === 'es' ? 
            persona.descripcion.map((parrafo,index) => {
              if(index === 0){
                return(
                <>{parrafo}</>
                );
              }else{
                return(
                <><br /><br />{parrafo}</>
                );
              }
              })
            :
            persona.descripcion_en.map((parrafo,index) => {
              if(index === 0){
                return(
                <>{parrafo}</>
                );
              }else{
                return(
                <><br /><br />{parrafo}</>
                );
              }
              })}
            </p>
            </Col>
            
      </Col>
        </>
       
      )
    });

    const ver_personas = logos.map((item) => {
      return(
        <>
        <div className="img_contenedor_ponente">
        <img src={item.img_src} alt={item.nombre} className="img_ponente" />
        <div className="overlay_ponente">
              <div className="text_ponente">
              <span>
              <img src={item.bandera} style={{width:'30px',display:'inline'}} />
              <img src={item.bandera2} style={{width:'30px',display:'inline'}} />
              </span>
              <br />
              
                <span className='nombre_hover_ponente'>{item.nombre} {item.apellido}</span><br />
                <span className='previu_hover_ponente'>{idioma === 'es' ? item.previu : item.previu_en}</span><br />
                {item.index === 10 ?
                <></>
                :
                <span onClick={()=>{setVerModal(true);setSelectPersona(item.index)}} className='masinfo_hover_ponente'>{idioma === 'es' ? 'Más información' : 'More information'}</span>
    }
              </div>
            </div>
          </div>
        </>
      )
    });
    const ver_info_personas = logos.filter((personas) =>{
      if(personas.index === select_persona)
      return true;
      else
      return false;
    }).map((item) => {
      return(
        <>
          <Row justify='center'>
            <Col xs={8} md={10}><br /><br />
              <img className='img_modal_ponente' src={item.img_src} style={{width:'100%'}} />
            </Col>
            <Col xxs={20} md={14}style={{padding:'5px 10px'}}>
            <p className='nombre_modal_ponente'>{item.nombre} {item.apellido}</p>
            <p className='descripcion_modal_ponente'>{idioma === 'es' ? item.descripcion : item.descripcion_en}</p>
            </Col>
          </Row>
        </>
      )
    });
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        
        <CaretRightFilled className={className} style={{ ...style, display: "block", color:'white', fontSize:'34px' }} onClick={onClick} />
      );
    }
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'white', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
      );
    }

    const settingsPonentes = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows:true,
      nextArrow:<SampleNextArrow /> ,
      prevArrow:<SamplePrevArrow  />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

    const settingslogos = {
            infinite: true,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 10000,
            speed:2000,
            dots: false,
            adaptiveHeight: true
    };

    const cambiarCarousel = (adelante) => {
      if(adelante){
          if(activar_carouse === 5){
            setActivaCarosel(1);
          }else{
            setActivaCarosel(activar_carouse+1);
          }
          ref_carousel.current.slickNext();
      }else{
          if(activar_carouse === 0){
            setActivaCarosel(5);
          }else{
            setActivaCarosel(activar_carouse-1);
          }
          ref_carousel.current.slickPrev();
      }
      
      }

    return (
      <>
      <Row justify="center" align="middle" className='contenedor_ponente'>
        <Col span={20}>
        <img src={idioma === 'es' ? titulo_conferencista : titulo_conferencista_en } style={{width:'100%'}} />
          </Col>
          <Col span={24}>
          </Col>
          <Col span={0}>
          <CaretLeftFilled className="flecha_agenda" onClick={() => {cambiarCarousel(false);}}/>
          </Col>
        <Col xs={10} md={16}>
        <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'0px'}} className="slide_ponente">
        {ver_personas}
        </Slider>
        </Col>
        <Col span={0}>
          <CaretRightFilled className="flecha_agenda" onClick={() => {cambiarCarousel(true);}}/>
          </Col>
        {/*<div className="col_flechas_ponentes_izquierda">
        <img alt="" src={flecha_izquierda} className="img_carousel_flecha flecha_izquierda" alt="carousel_izquierda" onClick={() => cambiarCarousel(false)} />
        </div>
        <div  className="col_flechas_ponentes_derecha">
        <img alt="" src={flecha_derecha} className="img_carousel_flecha flecha_derecha" alt="carousel_derecha" onClick={() => cambiarCarousel(true)} />
        </div>*/}
      </Row>
      <Modal 
      visible={ver_modal} onCancel={() => { setVerModal(false); }}
      title={null}
      footer={null}
      closable={true}
      width="750px"
                className=""
                forceRender={true}>
        {ver_info_personas}
      </Modal>
    </>
    );

};

export default CarouselPonentes;