import React, { useState, useContext, useEffect, useRef } from "react";
import { Col, Row } from "antd";

import img_1 from '../../assets/images/impacto-zero/ImpactoZ_Eric.png';
import img_2 from '../../assets/images/impacto-zero/ImpactoZ_Benjamin.png';
import img_3 from '../../assets/images/impacto-zero/Sume_Descripcion.jpg';
import img_4 from '../../assets/images/impacto-zero/Ies_Descripcion.jpg';
import '../../assets/css/participantes.css';

const Participantes = () => {
    return(
        <>
        <Row justify="center" align="top">
            <Col span={20}>
                <p className="totulo_info_regis">CON LA PARTICIPACIÓN DE:</p>
            </Col>
            <Col span={20}>
                <Row justify="center" align="top">
                    <Col xs={12} md={7}>
                        <img src={img_1} style={{width:'100%'}} />
                    </Col>
                    <Col xs={20} md={17} className="contenedor_info_regis">
                        <p className="nombre_info_regis">Eric Rondolat</p>
                        <p className="puesto_info_regis">CEO and Chairman of the Board of Management of Signify N.V.</p>
                        <p className="texto_info_regis">
                        Es desde el 2016, <strong>CEO y Presidente del Consejo de Administración de Signify N.V.</strong> Anteriormente fue <i>Vicepresidente Ejecutivo y CEO de Iluminación en Philips.</i> Antes de Philips, Eric ocupó el cargo de <i>Vicepresidente Ejecutivo para Asia Pacífico en Schneider Electric</i> en China y también fue <i>Vicepresidente Ejecutivo del Negocio de Energía en Schneider Electric</i> en Francia. Antes de ello, ocupó varios puestos de Alta Dirección en Schneider Electric en Australia, Argentina, Francia y Singapur. Eric es ingeniero egresado del <i>Institut National Polytechnique de Grenoble,</i> Francia y tiene un máster en Marketing Internacional de la Ecole Superieure de Commerce de Grenoble, Francia.
                        </p>
                    </Col>
                </Row>
                <Row justify="center" align="top">
                    <Col xs={12} md={7}>
                        <img src={img_2} style={{width:'100%'}} />
                    </Col>
                    <Col xs={20} md={17} className="contenedor_info_regis">
                        <p className="nombre_info_regis">L. Benjamín Romano</p>
                        <p className="puesto_info_regis">Arquitecto Fundador del Despacho LBR&A</p>
                        <p className="texto_info_regis">
                        Arquitecto mexicano egresado de la <i>Universidad Iberoamericana</i> y fundador del despacho LBR&A. Fue premiado en los <i>Iconic Awards: Innovative Architecture del Consejo Alemán de Diseño,</i> por su proyecto <strong>Residencia C73</strong>, construida bajo las premisas de la propuesta arquitectónica Net Zero. Un gran logro de Benjamín Romano es la Torre Reforma, edificio sustentable que cuenta con el distintivo <strong><i>LEED Platino,</i></strong> con una reducción del 45% su consumo de energía y del 30% en el consumo de agua potable. Ha recibido reconocimientos como la Medalla de Oro en <i>la XIV Bienal Nacional de Arquitectura Mexicana 2016; el Premio Augusto A. Álvarez 2014, otorgado por la excelencia en su trayectoria arquitectónica por la Federación de Colegio de Arquitectos de México;</i> el Premio Luis Barragán al Mérito Profesional; y el primer lugar Mundial al edificio inteligente otorgado por el <i>IBI de Chicago Illinois USA en 1992</i> por su proyecto <strong>Torre Chapultepec.</strong> Y recientemente fue nombrado <i>Académico Emérito de la Academia Nacional de Arquitectura.</i>
                        </p>
                    </Col>
                </Row>
                <Row justify="center" align="top">
                    <Col xs={12} md={7}>
                        <img src={img_3} style={{width:'100%'}} />
                    </Col>
                    <Col xs={20} md={17} className="contenedor_info_regis">
                        <p className="nombre_info_regis">Sustentabilidad para México A.C.</p><br />
                        <p className="texto_info_regis">
                        Es una asociación sin fines de lucro cuya visión es sumar esfuerzos para el fortalecimiento del mercado de la edificación sustentable en México, uniendo los esfuerzos de organismos privados, públicos, sociedad civil y organismos internacionales comprometidos con la sustentabilidad. SUMe es oficialmente el <i>Consejo establecido para México del World Green Building Council (WGBC), y tiene una alianza con el US Green Building Council (USGBC) / Green Business Certification Inc, (GBCI)</i> para generar programas educativos y de capacitación que permitan avanzar en la transformación del entorno construido de México hacia el ejercicio de prácticas de negocios sustentables.
                        <br /><br />
                        <strong>Visita: <a href="http://www.sume.org.mx" target="_blank" style={{textDecoration:'none', color:'#000000'}}>www.sume.org.mx</a></strong>
                        </p>
                    </Col>
                </Row>
                <Row justify="center" align="top">
                    <Col xs={12} md={7}>
                        <img src={img_4} style={{width:'100%'}} />
                    </Col>
                    <Col xs={20} md={17} className="contenedor_info_regis">
                        <p className="nombre_info_regis">Illuminating Engineering Society</p><br />
                        <p className="texto_info_regis">
                        Es la máxima autoridad técnica y educativa reconocida a nivel mundial para la iluminación. La IES fue establecida en 1906 en Estados Unidos y desde 1950 la <i>IES Mexico City Section</i> comenzó sus actividades en nuestro país. Nuestra misión es “Mejorar el entorno iluminado reuniendo a aquellos con conocimientos en iluminación y traduciendo ese conocimiento en acciones que beneficien al público”. Ofrecemos una gama de oportunidades para el desarrollo profesional, publicaciones, redes y recursos educativos a nuestros miembros ingenieros, arquitectos, diseñadores, educadores, estudiantes, contratistas, distribuidores, personal de servicios públicos, fabricantes y científicos en casi 60 países. A través de nuestro proceso acreditado por el <i>American National Standards Institute (ANSI)</i>, publicamos y mantenemos la <i>Lighting Library®</i>, con más de 100 estándares escritos por expertos en la materia en nuestros comités técnicos. En todos nuestros esfuerzos, nos esforzamos por mejorar la vida a través de la calidad de la luz.
                        <br/><br />
                        <strong>Para obtener más información sobre nosotros,<a href="http://www.ies.org" target="_blank" style={{textDecoration:'none', color:'#000000'}}>visite www.ies.org</a></strong>
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}
export default Participantes;
