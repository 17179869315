import React, { useEffect, useState } from 'react';
import '../../assets/css/general.css';
import {Element, scroller} from 'react-scroll';

import Header from './Header';
import Footer from './Footer';
import Evento from '../evento/Evento';
import Sala from '../conferencias/Sala';
import Registro from '../registro/Registro';
import {Switch, Route, useLocation } from 'react-router-dom';

import Admin from '../admin/Admin';
import VisualizarPreguntas from '../admin/VisualizarPreguntas';
import EstadisticaGrafica from '../conferencias/EstadisticaGrafica';

import Login from '../registro/Login';
import CerrarSesion from '../sesion/CerrarSesion';
import Perfil from '../sesion/Perfil';
import Error from "../auth/Error";

import PayPal from '../../utils/PayPal';
import PayPalIndependiente from '../../utils/PayPalIndependiente';

import ConferenciaUno from '../revivelo/ConferenciaUno';

import Conferencia from '../en-vivo/Conferencia';


import MesasDirectivas from '../evento/MesasDirectivas';

import { AuthContext } from '../../utils/AuthContext';

import '../../assets/css/global.css';
import Acuty from '../patrocinadores/paginas/Acuty';
import Construlita from '../patrocinadores/paginas/Construlita';
import Cooper from '../patrocinadores/paginas/Cooper';
import Ledvance from '../patrocinadores/paginas/Ledvance';
import Lutron from '../patrocinadores/paginas/Lutron';
import Signify from '../patrocinadores/paginas/Signify';
import Tci from '../patrocinadores/paginas/Tci';
import Toljy from '../patrocinadores/paginas/Toljy';
import Zeraus from '../patrocinadores/paginas/Zeraus';
import Stand from '../expo/Stand';
import Metricas from '../admin/Metricas';
import Creston from '../patrocinadores/paginas/Creston';
import Current from '../patrocinadores/paginas/Current';
import Lamp from '../patrocinadores/paginas/Lamp';
import ExpoWeb from '../expo/ExpoWeb';
import Expo from '../expo/Expo';
import Paneles from '../evento/Paneles';
import Conferencias from '../conferencias/Conferencias';

export const Master = () => {

  const [idioma, setIdioma] = useState('es');

  const [mostrar_login, setMostrarLogin] = useState(false);
  //let { usuario, datos } = useContext(Auth);

  const secciones = [
    //{componente:<Evento idioma={idioma}/>, url:'/'},
    //{componente:<Expo idioma={idioma}/>, url:'/patrocinadores'},
    //{componente:<Paneles idioma={idioma}/>, url:'/panel'},
    //{componente:<Conferencias idioma={idioma}/>, url:'/on-demand'},
    //{ componente: <Stand idioma={idioma} />, url: "/patrocinadores/:nombre" },
    //{componente:<Conferencia idioma={idioma}/>, url:'/en-vivo'},
    //{componente:<Proximo idioma={idioma}/>, url:'/en-vivo'},
    
    {componente:<PayPalIndependiente idioma={idioma}/>, url:'/pago'},
    {componente:<Registro setMostrarLogin={setMostrarLogin} idioma={idioma}/>, url:'/:paquete?'},
    {componente:<Registro setMostrarLogin={setMostrarLogin} idioma={idioma}/>, url:'/registro/:paquete?'},
    {componente:<PayPal idioma={idioma}/>, url:'/paypal'},

    /*{componente:<Acuty idioma={idioma}/>, url:'/patrocinadores/acuity-brands'},
    {componente:<Construlita idioma={idioma}/>, url:'/patrocinadores/construlita'},
    {componente:<Cooper idioma={idioma}/>, url:'/patrocinadores/cooper'},
    {componente:<Creston idioma={idioma}/>, url:'/patrocinadores/crestron'},
    {componente:<Current idioma={idioma}/>, url:'/patrocinadores/current'},
    {componente:<Lamp idioma={idioma}/>, url:'/patrocinadores/lamp'},
    {componente:<Ledvance idioma={idioma}/>, url:'/patrocinadores/ledvance'},
    {componente:<Lutron idioma={idioma}/>, url:'/patrocinadores/lutron'},
    {componente:<Signify idioma={idioma}/>, url:'/patrocinadores/signify'},
    {componente:<Tci idioma={idioma}/>, url:'/patrocinadores/tci'},
    {componente:<Toljy idioma={idioma}/>, url:'/patrocinadores/toljy'},
    {componente:<Zeraus idioma={idioma}/>, url:'/patrocinadores/zeraus'},*/

    //{componente:<DiaUno idioma={idioma}/>, url:'/conferencias/dia-uno'},
    //{componente:<DiaDos idioma={idioma}/>, url:'/conferencias/dia-dos'},
    //{componente:<DiaTres idioma={idioma}/>, url:'/conferencias/dia-tres'},
    //{componente:<DiaCuatro idioma={idioma}/>, url:'/conferencias/dia-cuatro'},
    //{componente:<DiaCinco idioma={idioma}/>, url:'/conferencias/dia-cinco'},

    //{componente:<EstadisticaGrafica  idioma={idioma}/>, url:'/graficas'},
    //{componente:<Metricas  idioma={idioma}/>, url:'/metricas'},

    //{componente:<MesasDirectivas  idioma={idioma}/>, url:'/mesas-directivas'},
  ];
  
  const secciones_auth = [
    //{componente:Sala, url:'/conferencias'},
    //{componente:Perfil, url:'/perfil'},
    //{componente:Login, url:'/login'},
    //{componente:Perfil, url:'/cuenta'},
    //{componente:Conferencia, url:'/en-vivo-edit'},
    //{componente:Proximo, url:'/en-vivo'},
    {componente:Admin, url:'/admin'},
    {componente:VisualizarPreguntas, url:'/visualizar-preguntas'},
    {componente:EstadisticaGrafica, url:'/graficas'},
    {componente:CerrarSesion, url:'/cerrar-sesion'}
  ];

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <Route path={seccion.url} exact render={(props)=>{
          return(
            seccion.componente
          );
        }} />
      );
    });

    let visualizar_secciones_auth = secciones_auth.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} idioma={idioma} />
      );
    });

    const location = useLocation();

    function sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }

    useEffect(() => {
      if (location.hash === "") {
        scroller.scrollTo("header");
      } else {
        scroller.scrollTo(location.hash);
      }
    });
  
    useEffect(() => {
      sleep(1000).then(() => {
        if (location.hash === "") {
          scroller.scrollTo("header");
        } else {
          scroller.scrollTo(location.hash);
        }
      });
    }, []);

  return(
    <>
    <AuthContext >
      <Element name="header" />
      <Header setMostrarLogin={setMostrarLogin} mostrar_login={mostrar_login} setIdioma={setIdioma} idioma={idioma} />
    <Switch>
      {visualizar_secciones}
      {visualizar_secciones_auth}
      <Route path='/*' exact component={Error} idioma={idioma} />
    </Switch>
    <Footer idioma={idioma}/>
    </AuthContext>
    </>
  );
}