import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import CarouselPatrocinadores from '../evento/CarouselPatrocinadores';
import ima from '../../../src/assets/images/01_impacDemo_home/impacto_logo_blanco.png';
import cintillo_footer from '../../../src/assets/images/01_impacDemo_home/Thethree_ImpacCintillo.png';

import { AiFillFacebook, AiOutlineTwitter, AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";

import btn_mail from '../../assets/images/2023/botones/ies_boton_Correo.png';
import btn_mail_hover from '../../assets/images/2023/botones/ies_boton_Correo_scroll.png';
import btn_mail_en from '../../assets/images/2023/botones/ies_boton_Correo.png';
import btn_mail_hover_en from '../../assets/images/2023/botones/ies_boton_Correo_scroll.png';

import btn_wsp from '../../assets/images/2023/botones/ies_boton_whats_scroll.png';
import btn_wsp_hover from '../../assets/images/2023/botones/ies_boton_whats.png';
import btn_wsp_en from '../../assets/images/2023/botones/ies_boton_whats_scroll.png';
import btn_wsp_hover_en from '../../assets/images/2023/botones/ies_boton_whats.png';

import logo_footer from '../../../src/assets/images/2023/logos_iconos/ies_MexicoCity_blanco.png';
import logo_footer_1 from '../../../src/assets/images/2023/logos_iconos/Ies_Monterrey_blanco.png';

import ubicacion from '../../../src/assets/images/2023/lugar/ubicacion_IES.jpg';
import ubicacion_en from '../../../src/assets/images/2023/lugar/ubicacion_IES_eng.jpg';
import ubicacion_movil from '../../../src/assets/images/2023/lugar/ubicacion_IES.jpg';
import ubicacion_en_movil from '../../../src/assets/images/2023/lugar/ubicacion_IES_eng.jpg';

import titulo_patrocinadores from '../../../src/assets/images/2023/titulos/ies_nuestrosPatrocinadores.png';
import titulo_patrocinadores_en from '../../../src/assets/images/2023/titulos/Titulos_ENG/ies_nuestrosPatrocinadores_ENG.png';

import titulo_masinfo from '../../../src/assets/images/2023/titulos/ies_MasInfo.png';
import titulo_masinfo_en from '../../../src/assets/images/2023/titulos/Titulos_ENG/ies_MasInfo_ENG.png';
import { Element } from 'react-scroll';


const Footer = (props) => {
  let location = useLocation();
  console.log(location);
  const {idioma} = props;
  const [hover,setHover]=useState('0');

  return(
    <>
    
   <Row justify='center' className='back_texto_footer'>
    <Col xs={20} md={20}>
            <p className='fase_footer' style={{textAlign:'justify'}}>
            "La ciencia exige que descarbonicemos rápidamente nuestra economía y alcancemos cero emisiones netas en el 2050, para limitar el calentamiento global en 1.5 °C. Eso no se logrará sin el liderazgo de las corporaciones, quienes desempeñan un papel fundamental para responder a este desafío existencial, mediante la innovación y la rápida transición a productos y servicios con bajas emisiones de carbono.
            </p>
            <p className='fase_footer' style={{textAlign:'center'}}>
            Maxfield Weiss<br />
            Director de Compromiso Corporativo • Carbon Disclosure Project Europe (CDP Europa)
            </p>
          </Col>
   </Row>
<Row>
          
        </Row>
    <Row justify='space-around' align="middle" className="contenedor_footer" style={{display:'none'}}>
      <Col xs={10} lg={6}>
      <img src={logo_footer} className="logo_footer"/>
      </Col>
      <Col xs={0} lg={6}>
      <p className="texto_footer"><strong>#SeminarioIES2023</strong></p>
      <a href="https://www.facebook.com/IesSeccionMexico/" target="_blank"><AiFillFacebook className="redes" /></a>
      <a href="https://www.instagram.com/ies_mexico/" target="_blank"><AiOutlineInstagram className="redes" /></a>
      <a href="https://twitter.com/iesseccionmexic" target="_blank"><AiOutlineTwitter className="redes" /></a>
      </Col>
      <Col xs={10} lg={6}>
      <img src={logo_footer_1} className="logo_footer"/>
      </Col>
    </Row>
    
    </>
  );
}

export default Footer;