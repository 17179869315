import React, {useEffect, useState, useContext} from 'react';
import { useLocation } from 'react-router';
import { Row, Col} from 'antd';
import Firebase from '../../utils/firebaseConfig';
import { Auth } from "../../utils/AuthContext";

import '../../assets/css/global.css';
import Acuty from '../patrocinadores/paginas/Acuty';
import Construlita from '../patrocinadores/paginas/Construlita';
import Cooper from '../patrocinadores/paginas/Cooper';
import Ledvance from '../patrocinadores/paginas/Ledvance';
import Lutron from '../patrocinadores/paginas/Lutron';
import Signify from '../patrocinadores/paginas/Signify';
import Tci from '../patrocinadores/paginas/Tci';
import Toljy from '../patrocinadores/paginas/Toljy';
import Zeraus from '../patrocinadores/paginas/Zeraus';
import Creston from '../patrocinadores/paginas/Creston';
import Current from '../patrocinadores/paginas/Current';
import Lamp from '../patrocinadores/paginas/Lamp';

const app = new Firebase();
const nombre_evento = 'impacto-zero';

const Stand = (props) => {
    const { usuario,datos } = useContext(Auth);
    const [dia, setDia] = useState(1);
    const { idioma } = props;

    useEffect(()=>{
        app.obtener_objetos(nombre_evento+`/configuracion`,true,(data)=>{
            setDia(data[0].dia_metricas);
        });
    },[]);

    const metricas = (tipo, nombre_stand) => {
        let metrica = {dia: dia, ...datos}
        if(usuario){
            app.verificar_objeto_dia(nombre_evento+`/stands/${nombre_stand}/${tipo}`,'correo',datos.correo, dia, (data) => {
            if(!data){
                app.escribir(nombre_evento+`/stands/${nombre_stand}/${tipo}`,undefined,metrica,()=>{});
            }
            });
        }else{
            app.escribir(nombre_evento+`/stands/${nombre_stand}/${tipo}_clic`,undefined,{clic:1, dia: dia},()=>{});
        }
    }

    const stands = [
        {url:'/patrocinadores/acuity-brands', nombre:'acuity-brands', js: <Acuty onMetrica={metricas} nombre='acuity-brands' idioma={idioma} />},
        {url:'/patrocinadores/construlita', nombre:'construlita', js: <Construlita onMetrica={metricas} nombre='construlita' idioma={idioma} />},
        {url:'/patrocinadores/cooper', nombre:'cooper', js: <Cooper onMetrica={metricas} nombre='cooper' idioma={idioma} />},
        {url:'/patrocinadores/crestron', nombre:'crestron', js: <Creston onMetrica={metricas} nombre='crestron' idioma={idioma} />},
        {url:'/patrocinadores/current', nombre:'current', js: <Current onMetrica={metricas} nombre='current' idioma={idioma} />},
        {url:'/patrocinadores/lamp', nombre:'lamp', js: <Lamp onMetrica={metricas} nombre='lamp' idioma={idioma} />},
        {url:'/patrocinadores/ledvance', nombre:'ledvance', js: <Ledvance onMetrica={metricas} nombre='ledvance' idioma={idioma} />},
        {url:'/patrocinadores/signify', nombre:'signify', js: <Signify onMetrica={metricas} nombre='signify' idioma={idioma} />},
        {url:'/patrocinadores/toljy', nombre:'toljy', js: <Toljy onMetrica={metricas} nombre='toljy' idioma={idioma} />},
        {url:'/patrocinadores/lutron', nombre:'lutron', js: <Lutron onMetrica={metricas} nombre='lutron' idioma={idioma} />},
        {url:'/patrocinadores/tci', nombre:'tci', js: <Tci onMetrica={metricas} nombre='tci' idioma={idioma} />},
        {url:'/patrocinadores/zeraus', nombre:'zeraus', js: <Zeraus onMetrica={metricas} nombre='zeraus' idioma={idioma} />},
        
    ];

const location = useLocation();
    return(
        <Row>
            <Col span={24}>
                {stands.filter(stand => stand.url === location.pathname).map(stand => {
                    return stand.js;
                })}
            </Col>
        </Row>
    );
}

export default Stand;
