import React, { useState } from 'react';
import { Row, Col, Carousel, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import '../../assets/css/agenda.css';

import miercoles from '../../assets/images/2023/botones/ies_boton_Miercoles.png';
import miercoles_hover from '../../assets/images/2023/botones/ies_boton_Miercoles_scroll.png';
import jueves from '../../assets/images/2023/botones/ies_boton_Jueves.png';
import jueves_hover from '../../assets/images/2023/botones/ies_boton_Jueves_scroll.png';

import miercoles_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Miercoles_ENG.png';
import miercoles_hover_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Miercoles_scroll_ENG.png';
import jueves_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Jueves_ENG.png';
import jueves_hover_en from '../../assets/images/2023/botones/botones_ENG/ies_boton_Jueves_scroll_ENG.png';

import titulo_agenda from '../../assets/images/2023/titulos/ies_Agenda.png';
import titulo_agenda_en from '../../assets/images/2023/titulos/Titulos_ENG/ies_Agenda_ENG.png';

import flag_french from '../../assets/images/2023/banderas/ies_Francia.png';
import flag_greak from '../../assets/images/2023/banderas/ies_Grecia.png';
import flag_india from '../../assets/images/2023/banderas/ies_India.png';
import flag_mex from '../../assets/images/2023/banderas/ies_Mexico.png';
import flag_peru from '../../assets/images/2023/banderas/ies_Peru.png';
import flag_suecia from '../../assets/images/2023/banderas/ies_Suecia.png';
import flag_usa from '../../assets/images/2023/banderas/ies_Usa.png';
import flag_ingla from '../../assets/images/2023/banderas/ies_Inglaterra.png';
import flag_chile from '../../assets/images/2023/banderas/ies_Chile.png';
import flag_espa from '../../assets/images/2023/banderas/ies_Espania.png';
import flag_veneca from '../../assets/images/2023/banderas/ies_Venezuela.png';
import flag_ita from '../../assets/images/2023/banderas/ies_Italia.png';


import Slider from 'react-slick';

const dias = [
  { hora: '8:00', tipo: 1, titulo: 'Registro', titulo_en: 'Registration', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },
  { hora: '9:00', tipo: 1, titulo: 'Ceremonia Inauguracion', titulo_en: 'Opening Ceremony', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },
  { hora: '9:45', tipo: 1, titulo: 'Diseñamos Humanos a través de la Luz', titulo_en: 'We Design Humans Through Light', nombre: [<><strong>Daniela Viloria</strong><br />Dany Viloria Design Consulting</>], lugar: '', lugar_en: '', bandera: flag_veneca, bandera2: flag_espa, extra: '', dia: '1', 
  descripcion:[<>¿Somos conscientes hasta qué punto cualquier diseño puede re- diseñar de vuelta al humano? Igual que el diseño de una silla determina el tiempo de permanencia, capacidad de atención y salud postural de quien se siente; la gestión consciente o inconsciente de los parámetros lumínicos termina por aumentar o limitar la percepción humana que asumimos como “la realidad”. Esta interpretación incide de manera directa en nuestro desempeño físico y psicológico y, por tanto, en las decisiones que tomamos en cada momento. El Diseño Ontológico es una corriente filosófica que puede ayudar a redefinir las buenas prácticas del diseñador de iluminación</>], 
  descripcion_en:[<>Are we aware to what extent any design can re-design back to the human? Like the design of a chair determines the residence time, attention span and postural health of who sits; conscious or unconscious management of lighting parameters ends up increasing or limit the human perception that we assume as “reality”.<br />
    This interpretation has a bearing directly in our physical and psychological performance and, therefore, in the decisions we make in each moment. The Ontological Design is a philosophical current that can help to redefine the good practices of the lighting designer.</>], ver_modal:true  },
  { hora: '11:00', tipo: 2, titulo: 'Break', titulo_en: 'Break', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },
  { hora: '11:30', tipo: 1, titulo: 'Confesiones de un Auditor de Iluminación de la Vieja Guardia', titulo_en: 'Confessions of an Old School Lighting Auditor', nombre: [<><strong>Frank Agraz</strong><br />Eco Engineering, Inc.</>], lugar: '', lugar_en: '', bandera: flag_usa, bandera2: '', extra: '', dia: '1', 
  descripcion:[<>Cuando se mejore una instalación o espacio exterior con un nuevo sistema de iluminación, es absolutamente fundamental captar las condiciones existentes para tener éxito en cualquier reconversión del proyecto. Sin una evaluación precisa, los profesionales de iluminación no pueden ofrecer una propuesta confiable de costos y ahorros de la reconversión.<br />
    Únase a nosotros mientras exploramos los consejos y trucos de recopilar las condiciones existentes y maximizar las posibilidades de que sea un proyecto exitoso.</>], 
  descripcion_en:[<>When upgrading a facility or exterior space with a new lighting system, capturing the existing conditions is absolutely fundamental to the success of any retrofit project. Without an accurate assessment, lighting practitioners cannot possibly deliver a confident retrofit cost and savings proposal.<br />
    Join us as we explore the tips and tricks of collecting existing conditions and maximizing the chances for a successful project.</>], ver_modal:true},
  { hora: '12:45', tipo: 1, titulo: 'Privilegio de Luz: Un marco práctico de iluminación holística en comunidades desatendidas', titulo_en: 'Light Privilege: A practical framework for holistic lighting in underserved communities', nombre: [<><strong>Nick Albert</strong><br />Chromatic, Inc.</>], lugar: '', lugar_en: '', bandera: flag_usa, bandera2: '', extra: '', dia: '1', 
  descripcion:[<>Light Privilege, es una teoría que se centra en cómo una pobre iluminación es creada y perpetuada por un racismo estructural, intencionalmente o no, y que los habitantes de espacios bien iluminados son ajenos a los beneficios que reciben por el racismo.<br />
    La luz y la iluminación a menudo se dan por sentadas en nuestra sociedad. Pero la oscuridad puede ser tanto el lastre de los muy pobres, como el privilegio de los muy ricos. La oportunidad de jugar en calles bien iluminadas, o el derecho a disfrutar de la oscuridad apacible del patio trasero, puede ser que tenga más que ver con el estatus económico y posición social, que con lo que es mejor para una comunidad.<br />
    Más aún, el papel de la luz puede ser complicado. La iluminación puede brindar seguridad y protección, pero también puede contribuir al estrés incesante de los vigilados. La oscuridad puede ser una barrera para la oportunidad o posibilidad de disfrutar la belleza pastoral de la vida nocturna.<br />
    La comprensión moderna de la ciencia de la luz centrada en el ser humano, combinada con tecnologías de iluminación que avanzan rápidamente, generan la oportunidad de discutir un nuevo enfoque para iluminar nuestras comunidades. Comprender cómo la reproducción cromática, la agudeza visual y la sensibilidad circadiana se relacionan con los estándares municipales y las medidas de seguridad, puede comenzar a informar cómo podemos integrar la tecnología para crear entornos mejor iluminados para las personas; entornos que apoyen el derecho a la luz, así como una iluminación adecuada para todos.</>], 
  descripcion_en:[<>Light Privilege is a theory focusing on how poor lighting quality is created and perpetuated by structural racism, intentionally or not, and that inhabitants of well-lit spaces are oblivious to the benefits they receive because of racism.<br />
    Light and lighting are often taken for granted in our society. But darkness can be both the burden of the very poor or the privilege of the very wealthy. The opportunity to play on well-lit streets or the right to enjoy the peaceful darkness of your backyard can have more to do with economic status and social position than what is best for a community.<br />
    Yet, the role of light can be complicated. Lighting can provide safety and security but can also contribute to the unrelenting stress of the surveilled. Darkness can be a barrier to opportunity or a chance to enjoy the pastoral beauty of the nighttime environment.<br />
    Modern understanding of the science of human-centric light combined with rapidly advancing lighting technologies create the opportunity to discuss a new approach to lighting our communities. Understanding how color rendering, visual acuity and circadian sensitivity tie into municipal standards and safety measures can begin to inform how we can integrate technology to create better illuminated environments for people; environments which support the right to light as well as the right light for everyone.</>], ver_modal:true },
  { hora: '14:00', tipo: 2, titulo: 'Business Lunch', titulo_en: 'Business Lunch', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},
  { hora: '15:00', tipo: 1, titulo: 'La Evolución del Diseño de Iluminación en Mexico', titulo_en: 'The Evolution of the Lighting Design in Mexico', nombre: [<><strong>Panel</strong></>], lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},
  { hora: '16:15', tipo: 1, titulo: 'Diseñar la Luz, Proteger la Oscuridad', titulo_en: 'Designing the Light, Protecting the Darkness', nombre: [<><strong>Paulina Villalobos</strong><br />DIAV Architectural Lighting Design</>], lugar: '', lugar_en: '', bandera: flag_chile, bandera2: '', extra: '', dia: '1', 
  descripcion:[<>La Contaminación Lumínica es una preocupación creciente debido a su acelerado crecimiento y los impactos negativos que implica, no sólo por la privación de ver un cielo estrellado, sino también por el impacto ambiental a la salud y calidad de vida. La solución: es el Diseño. La iniciativa “Noche Zero” plantea incorporar en el método de iluminación arquitectónica, las variables de protección de la noche. El valor de la luz y la oscuridad dentro del método de diseño.<br />
    Para ello, también necesitaremos generar nuevos conceptos de iluminación, que no sólo sean Luz contra Oscuridad, sino que también incorporen otras cualidades de la luz en nuestro lenguaje.</>], 
  descripcion_en:[<>Light Pollution is a growing concern due to its rapid growth and the negative impacts it entails, not only because of the deprivation of seeing a starry sky, but also due to the environmental impact on health and life quality.
    The solution: is the Design. The “Night Zero” initiative proposes incorporating night protection variables into the architectural lighting method. The value of light and dark within the design method.<br />
    For this, we will also need to generate new concepts of light, which are not only Light versus Dark, but also incorporate other light qualities into our language.</>], ver_modal:true},
  { hora: '17:30', tipo: 2, titulo: 'Break', titulo_en: 'Break', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},
  { hora: '18:00', tipo: 1, titulo: [<>Mesas Cruzadas<br /><br /><br /><hr style={{backgroundColor:'#8e50f6', border:'none',height:'2px'}} /><br />Muestra de Productos</>], titulo_en: [<>Cross Tables<br /><br /><br /><hr style={{backgroundColor:'#8e50f6', border:'none',height:'2px'}} /><br />Product Show</>], nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},
  { hora: '19:00', tipo: 1, titulo: 'Muestra de Productos', titulo_en: 'Product Show', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false  },
  { hora: '20:00', tipo: 2, titulo: 'Final', titulo_en: 'End', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '1', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },

  { hora: '8:00', tipo: 1, titulo: 'Registro', titulo_en: 'Registration', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },
  { hora: '8:30', tipo: 1, titulo: 'Control de Iluminación: Creatividad y Técnica en un Análisis Multifactorial', titulo_en: 'Lighting Control: Creativity and Technique in a Multifactorial Analysis', nombre: [<><strong>Pedro Garza</strong><br />Mantenimiento Arquitectonico Integral (MAI)</>], lugar: '', lugar_en: '', bandera: flag_mex, bandera2: '', extra: '', dia: '2', 
  descripcion:[<>El objetivo es crear conciencia sobre la importancia de tomar en cuenta todos y cada uno de los diferentes factores a considerar para los sistemas de control de iluminación, desde el correcto diseño, flexibilidad, eficiencia, comodidad para el usuario, durabilidad, mantenimiento, seguridad, costos entre otros de igual relevancia.<br />
    Veremos ejemplos de diferentes diseños para el Control de Iluminación que toman en cuenta más aspectos que sólo los efectos estéticos deseados, dando como resultado una satisfacción total de las necesidades y objetivos del propietario.</>], 
  descripcion_en:[<>The objective is to create awareness about the importance of taking into account each and every one of the different factors to be considered for lighting control systems, from the correct design, flexibility, efficiency, user comfort, durability, maintenance, safety, costs among others of equal relevance.<br />
    We will see examples of different designs for Lighting Control that take into account more aspects than just the desired aesthetic effects, resulting in a total satisfaction of the owner needs and objectives.</>], ver_modal:true },
  { hora: '9:45', tipo: 1, titulo: 'Color Fuera de las Pautas: superando los límites de la experiencia y el diseño', titulo_en: 'Color Outside the Lines: pushing the boundaries of experience and design', nombre: [<><strong>Cecilia Ramos</strong><br />Architectural Market, LUTRON</>], lugar: '', lugar_en: '', bandera: flag_usa, bandera2: '', extra: '', dia: '2', 
  descripcion:[<>La iluminación da forma a todo lo que vemos, y el color no es la excepción. ¿Cómo podemos pensar acerca de la iluminación de manera diferente, y específicamente en el impacto de tecnologías innovadoras que dan acceso a nuevas dimensiones de cromaticidad, para ofrecer a los diseñadores una gama ampliada con la cual puedan realizar sus visiones?<br />
    La presentación explorará esta oportunidad a través de la lente de la ciencia de la iluminación, y su impacto en estudios de casos de la vida real, a través de las verticales del arte, hospitalidad y bienestar, que inspiren futuras posibilidades de diseño.</>], 
  descripcion_en:[<>Light shapes all that we see, and color is no exception. How can we think about light differently – and specifically the impact of innovative technologies that give access to new dimensions of chromaticity – to offer designers an expanded palette with which to realize their visions?<br />
    The presentation will explore this opportunity through the lens of lighting science and its impact in real-life case studies across art, hospitality, and wellness verticals to inspire future design possibilities.</>], ver_modal:true},
  { hora: '11:00', tipo: 2, titulo: 'Break', titulo_en: 'Break', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },
  { hora: '11:30', tipo: 1, titulo: 'Presente y Futuro de la Industria de Iluminacion en Mexico', titulo_en: 'Present and Future of the Lighting Industry in Mexico', nombre: [<><strong>Panel</strong></>], lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },
  { hora: '12:45', tipo: 1, titulo: '¡Domina el Rendimiento de Color!', titulo_en: 'Get a grip on Color Rendition!', nombre: [<><strong>Tony Esposito</strong><br />Lighting Researcher Solutions</>], lugar: '', lugar_en: '', bandera: flag_usa, bandera2: '', extra: '', dia: '2', 
  descripcion:[<>El Rendimiento de Color es un aspecto de importancia crítica en el diseño de iluminación en el entorno construido. ¿Pero, qué es esto? ¿Por qué es tan importante? ¿Qué es TM-30 y qué significan todos esos números? ¿Qué es IES TM-30 ANEXO E y cómo lo uso?<br />
    Estas preguntas, y muchas más, se abordarán en esta charla completa sobre la reproducción del color.<br />
    ¡Se mostrarán ejemplos reales!</>], 
  descripcion_en:[<>Color Rendering is a critically important aspect of lighting design in the built environment. But what is it? Why is it so important? What is TM-30, and what do all those numbers mean? What is IES TM-30 ANNEX E, and how do I use it?<br />
    These questions, and many more, will be addressed in this comprehensive talk about color rendering.<br />
    Real examples will be shown!</>], ver_modal:true},
  { hora: '14:00', tipo: 2, titulo: 'Business Lunch', titulo_en: 'Business Lunch', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false },
  { hora: '15:00', tipo: 1, titulo: '¿Derecho o privilegio? Sustentabilidad en el Diseño de Iluminación', titulo_en: 'Right or Privilege? Sustainability in Lighting Design', nombre: [<><strong>Chiara Carucci</strong><br />Chiara Carucci Lighting Design</>], lugar: '', lugar_en: '', bandera: flag_ita, bandera2: '', extra: '', dia: '2', 
  descripcion:[<>Chiara Carucci te llevará en un viaje a través de diferentes aspectos de Sustentabilidad: económicos, sociales y ambientales. Una buena iluminación puede ayudar a las personas a sentirse seguras y protegidas, pero también puede contribuir a la cohesión social y a la economía nocturna. Esto es cierto no solo en los centros de las ciudades y países desarrollados. Entornos bien iluminados en zonas desfavorecidas mejoran la autoestima y expectativas de crecimiento, más aún si se invita a participar en el proyecto a los ciudadanos, especialmente a grupos minoritarios. Además, los profesionales de la iluminación tienen tanto la oportunidad, como la responsabilidad de actuar como protectores de los recursos y del medio ambiente.<br />
    Esta plática resaltará el efecto de la iluminación en todos los elementos vivos y explorará diferentes medidas de mitigación, ya que limitar la luz desperdiciada no es suficiente para proteger el medio ambiente y la biodiversidad.<br />
    En pocas palabras: en la planificación de una ciudad, especialmente donde está en juego la conservación del patrimonio natural y cultural, la colaboración es vital y debemos apuntar a diseñar una iluminación adecuada, en el momento adecuado.</>], 
  descripcion_en:[<>Chiara Carucci will take you on a journey through different aspects of Sustainability: economic, social and environmental. Good lighting can help people feel safe and secure, but it also can contribute to social cohesion and night-time economy. This is true not only in city centers and developed countries. Well-lit environments in underprivileged areas improve self-worth and growth expectations, even more if citizens, especially minority groups, are invited to participate in the project. Moreover, lighting professionals have both an opportunity and a responsibility to act as a protector of resources and of the environment.<br />
    This talk will highlight the effect of light on all living items, and explore different mitigating measures, since limiting spill light is not enough to protect the environment and the biodiversity.<br />
    In a nutshell: in city planning, especially where natural and cultural heritage conservation is at stake, collaboration is vital, and we should aim to design the right light, at the right time.</>], ver_modal:true },
  { hora: '16:15', tipo: 1, titulo: 'Luz: Un poderoso lenguaje no-verbal', titulo_en: 'Light: A powerful non-verbal language', nombre: [<><strong>Katia Kolovea</strong><br />Archifos</>], lugar: '', lugar_en: '', bandera: flag_greak, bandera2: '', extra: '', dia: '2', 
  descripcion:[<>En un mundo que ha pasado por un período de turbulencia social, ambiental y política, con una pandemia global, guerras, terrorismo, racismo, enfermedades infecciosas, calentamiento global, etc., la luz proporciona nuevos medios de comunicación directa y no verbal entre las sociedades. La luz es un medio internacional que conecta culturas, traslada mensajes rápida y sencillamente, sin levantar barreras lingüísticas, de clase o raciales.<br />
    La pregunta es: ¿Cómo podemos maximizar el poder de la luz de la manera correcta? ¿Podríamos como diseñadores de iluminación, usar la luz como una herramienta para inspirar a las personas a actuar? El mundo necesita un medio que aumente la conciencia de una manera neutral. Es muy importante que el espacio urbano nos anime a involucrarnos y preocuparnos por nuestras comunidades.<br />
    Profundicemos e investiguemos los muchos aspectos de la luz como un medio de comunicación fundamental y significativo.</>], 
  descripcion_en:[<>In a world that has been going through a period of social, environmental, and political turmoil, with a global pandemic, wars, terrorism, racism, infectious diseases, global warming, and so on, light provides a new means of direct and non-verbal communication between societies. Light is an international medium that connects cultures, transfers messages quickly and simply, without raising linguistic, class, or racial barriers.<br />
    The question is: How can we maximize the power of light in the right way? Could we as lighting designers, use light as a tool to inspire people to take action? The world needs a medium that raises awareness in a neutral way. It is very important that urban space encourages us to engage and care about our communities.<br />
    Let us dive deeper and investigate the many aspects of light as a fundamental and meaningful communication medium.</>], ver_modal:true },
  { hora: '17:30', tipo: 2, titulo: 'Break', titulo_en: 'Break', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},
  { hora: '18:00', tipo: 1, titulo: 'Muestra de Productos', titulo_en: 'Product Show', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},
  { hora: '19:00', tipo: 1, titulo: 'Cocktail, Regalos y Ceremonía de Clausura', titulo_en: 'Cocktail, Gifts and Closing Ceremony', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},
  { hora: '20:00', tipo: 2, titulo: 'Final', titulo_en: 'End', nombre: '', lugar: '', lugar_en: '', bandera: '', bandera2: '', extra: '', dia: '2', 
  descripcion:[<></>], descripcion_en:[<></>], ver_modal:false},



]
const Agenda = (props) => {
  const [ver_dia, setVerDia] = useState(1);
  const { idioma } = props;
  const ref_carousel = React.createRef();
  const [activar_carouse, setActivaCarosel] = useState(0);

  const [select_tema, setSelectTema] = useState(0);
  const [ver_modal, setVerModal] = useState(false);

  let dias_vista = dias.filter(dia => {
    if (dia.dia == ver_dia) {
      return true;
    }
    return false;
  });

  const ver_info_personas = dias_vista.filter((personas, index) =>{
    if(index === select_tema)
    return true;
    else
    return false;
  }).map((item) => {
    return(
      <>
        <Row justify='center'>
          <Col xxs={20} md={20}style={{padding:'5px 10px'}}>
          <p className='conferencia_modal_actividad'>{idioma === 'es' ? item.titulo : item.titulo_en}</p>
          <p className='nombre_modal_actividad'>{item.nombre}</p>
          <p className='descripcion_modal_actividad'>{idioma === 'es' ? item.descripcion : item.descripcion_en}</p>
          </Col>
        </Row>
      </>
    )
  });



  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (

      <CaretRightFilled className={className} style={{ ...style, display: "block", color: '#8e50f6', fontSize: '34px', top:'10px' }} onClick={onClick} />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color: '#8e50f6', fontSize: '34px', marginLeft: '-10px', top:'10px' }} onClick={onClick} />
    );
  }

  const settingsAgenda = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  const cambiarCarousel = (adelante) => {
    if (adelante) {
      if (activar_carouse === 5) {
        setActivaCarosel(1);
      } else {
        setActivaCarosel(activar_carouse + 1);
      }
      ref_carousel.current.slickNext();
    } else {
      if (activar_carouse === 0) {
        setActivaCarosel(5);
      } else {
        setActivaCarosel(activar_carouse - 1);
      }
      ref_carousel.current.slickPrev();
    }

  }

  /*const irDelante = (adelante) => {
    if(adelante){
        if(ver_dia === 1){
          setVerDia(2);
        }else if (ver_dia === 2) {
          setVerDia(3);
        } else if (ver_dia === 3) {
          setVerDia(4);
        } else if (ver_dia === 4){
          setVerDia(5);
        } else{
          setVerDia(1);
        }
      }else{
        if(ver_dia === 1){
          setVerDia(5);
        }else if (ver_dia === 2) {
          setVerDia(1);
        } else if (ver_dia === 3) {
          setVerDia(2);
        } else if (ver_dia === 4) {
          setVerDia(3);
        } else{
          setVerDia(4);
        }
      }
  }*/

  

  let ver_actividades = dias_vista.map((seccion, index) => {
    if (seccion.tipo == 1) {
      return (
        <>
          <div>
            <Row className="divPrograma-" justify='center' align='middle'>
              <Col span={24}>
                <p className='hora_agenda'><strong>{seccion.hora}</strong></p>
              </Col>
              <Col span={24} className='cotenedor_actividad'>
                <div >
                  <p className='actividad_agenda'><strong>{idioma === 'es' ? seccion.titulo : seccion.titulo_en}</strong></p>
                  <p className='persona_agenda'>{seccion.nombre}</p>
                  <p className='persona_agenda'>
                  <img src={seccion.bandera} style={{width:'30px',display:'inline'}} />
              <img src={seccion.bandera2} style={{width:'30px',display:'inline'}} />
                  </p>
                  <p className='lugar_agenda'>{idioma === 'es' ? seccion.lugar : seccion.lugar_en}</p>
                  {seccion.ver_modal === true ? <><p className='masinfo_hover_actividad' onClick={()=>{setSelectTema(index);setVerModal(true);}}>Leer mas</p></> : <></>}
                </div>
              </Col>
            </Row>
          </div>
        </>
      )
    } else if (seccion.tipo == 2) {
      return (
        <>
          <div>
            <Row className="divPrograma-" justify='center' align='middle'>
              <Col span={24}>
                <p className='hora_agenda'><strong>{seccion.hora}</strong></p>
              </Col>
              <Col span={24} className='cotenedor_actividad' style={{ backgroundColor: '#d4d4d4' }}>
                <div >
                  <p className='actividad_agenda_2'>{idioma === 'es' ? seccion.titulo : seccion.titulo_en}</p>
                  <p className='lugar_agenda'>{idioma === 'es' ? seccion.lugar : seccion.lugar_en}</p>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )
    }
  })


  return (
    <>
      <Row justify='center' className='contenedor_agenda_back'>
        <Col xs={24} md={24} className="">
           <img src={idioma === 'es' ? titulo_agenda : titulo_agenda_en } style={{width:'100%'}} />
          {/*<Row justify="center" align="middle">
            <Col span={0}>
              <CaretLeftFilled className="flecha_agenda" onClick={() => { cambiarCarousel(false); }} />
            </Col>
            <Col span={0}>
              <CaretRightFilled className="flecha_agenda" onClick={() => { cambiarCarousel(true); }} />
            </Col>
  </Row>*/}
        </Col>
        <Col xs={22} md={22} className='cuadro_agenda'>
          <Row justify="center" align="middle">
            <Col span={24}>
              <br />
            </Col>
            <Col xs={12} md={6} onClick={() => { setVerDia(1) }} className="ponter_select">
              {idioma === 'es' ?
                <img src={ver_dia === 1 ? miercoles_hover : miercoles} style={{ width: '100%' }} />
                :
                <img src={ver_dia === 1 ? miercoles_hover_en : miercoles_en} style={{ width: '100%' }} />}
            </Col>
            <Col xs={12} md={6} onClick={() => { setVerDia(2) }} className="ponter_select">
              {idioma === 'es' ?
                <img src={ver_dia === 2 ? jueves_hover : jueves} style={{ width: '100%' }} />
                :
                <img src={ver_dia === 2 ? jueves_hover_en : jueves_en} style={{ width: '100%' }} />}
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col xs={20} lg={20} className="divPrograma-" style={{ height: '100%' }}>
              <Row justify="center" align="middle">
                <Col xs={20} md={24}>
                  <Slider ref={ref_carousel} {...settingsAgenda} style={{ margin: '10px', padding: '0px', height: '100%' }} className="">
                    {ver_actividades}
                  </Slider>

                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <p className='cambios_agenda'>{idioma === 'es' ? '*Todos los horarios y contenido están sujetos a cambios sin previo aviso' : ''}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal 
      visible={ver_modal} onCancel={() => { setVerModal(false); }}
      title={null}
      footer={null}
      closable={true}
      width="750px"
                className=""
                forceRender={true}>
        {ver_info_personas}
      </Modal>
    </>

  );
}

export default Agenda;