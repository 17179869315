import React from 'react';
import { Row, Col } from 'antd';

import img_wsp from '../../assets/images/2023/logos_iconos/Ies_ayuda.png';
import img_wsp_en from '../../assets/images/2023/logos_iconos/Iconos_ENG/Ies_ayuda_ENG.png';

const Whatsapp = (props) => {

  //const [mostrar_wsp_bl, mostrarWspBlanco] = useState(false);
  const {idioma} = props;
  
  return (
    <Row style={{ }} className="content_wsp_fixed">
      <Col xs={{span:0, offset:0}} lg={{span:2, offset:0}}>
        {idioma === 'es'?
          <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=Necesito%20ayuda%20con%20el%20Seminario%20de%20Iluminación%20IES%20México">
          <img alt="" src={img_wsp} className="sticky_wsp" />
          </a>
          :
          <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=I%20need%20help%20with%20the%20IES%20Mexico%20Lighting%20Seminar">
          <img alt="" src={img_wsp_en} className="sticky_wsp" />
          </a>
          }
      </Col>
    </Row>
  );
}
export default Whatsapp;