import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "../../utils/AuthContext";
import axios from "axios";
import Firebase from '../../utils/firebaseConfig';

import img_logo_1 from '../../assets/images/2023/stands/movil/Ies_Acuity.png';
import img_logo_2 from '../../assets/images/2023/stands/movil/Ies_Construlita.png';
import img_logo_3 from '../../assets/images/2023/stands/movil/Ies_Cooper.png';
import img_logo_4 from '../../assets/images/2023/stands/movil/Ies_Current.png';
import img_logo_5 from '../../assets/images/2023/stands/movil/Ies_Lamp.png';
import img_logo_6 from '../../assets/images/2023/stands/movil/Ies_Ledvance.png';
import img_logo_7 from '../../assets/images/2023/stands/movil/Ies_Lutron.png';
import img_logo_8 from '../../assets/images/2023/stands/movil/Ies_Signify.png';
import img_logo_9 from '../../assets/images/2023/stands/movil/Ies_Tci.png';
import img_logo_10 from '../../assets/images/2023/stands/movil/Ies_Toljy.png';
import img_logo_11 from '../../assets/images/2023/stands/movil/Ies_Zeraus.png';
import img_logo_12 from '../../assets/images/2023/stands/movil/Ies_Creston.png';

import { Link } from 'react-router-dom';


let logos = [
    { index: 0, img_src: img_logo_1, nombre: 'acuity-brands', url:'/patrocinadores/acuity-brands' },
    { index: 1, img_src: img_logo_2, nombre: 'construlita', url:'/patrocinadores/construlita' },
    { index: 2, img_src: img_logo_3, nombre: 'cooper', url:'/patrocinadores/cooper' },
    { index: 11, img_src: img_logo_12, nombre: 'crestron', url:'/patrocinadores/crestron' },
    { index: 3, img_src: img_logo_4, nombre: 'current', url:'/patrocinadores/current' },
    { index: 4, img_src: img_logo_5, nombre: 'lamp', url:'/patrocinadores/lamp' },
    { index: 5, img_src: img_logo_6, nombre: 'ledvance', url:'/patrocinadores/ledvance' },
    { index: 6, img_src: img_logo_7, nombre: 'lutron', url:'/patrocinadores/lutron' },
    { index: 7, img_src: img_logo_8, nombre: 'signify', url:'/patrocinadores/signify' },
    { index: 8, img_src: img_logo_9, nombre: 'tci', url:'/patrocinadores/tci' },
    { index: 9, img_src: img_logo_10, nombre: 'toljy', url:'/patrocinadores/toljy' },
    { index: 10, img_src: img_logo_11, nombre: 'zeraus', url:'/patrocinadores/zeraus' },
    
]


function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

const ExpoMovil = (params) => {

    const { num_items } = params;

    const app = new Firebase();
    const { usuario, datos } = useContext(Auth);
    const history = useHistory();
  const location = useLocation();
  const [standNombre, setSatndNombre] = useState('');
  const nombre_evento = 'impacto-zero'; 
  const [dia, setDia] = useState(1);


  const almacenarClicStand = (nombre_pat) => {
    let metrica = {dia: dia, ...datos}
    if(usuario){
      app.verificar_objeto_dia(nombre_evento+`/stands/${nombre_pat}/stand`,'correo',datos.correo,dia, (data) => {
        if(!data){
          app.escribir(nombre_evento+`/stands/${nombre_pat}/stand`,undefined,metrica,()=>{});
        }
      });
    }else{
      app.escribir(nombre_evento+`/stands/${nombre_pat}/stand_clic`,undefined,{clic:1, dia: dia},()=>{});
    }
  };
  


  const eventHandler = (req) => {
    almacenarClicStand(req);
    console.log('reeeeeww',req);
  };

    const settingslogos = {
        className: "center",
            centerMode: true,
            infinite: true,
            slidesToShow: num_items,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 200,
            speed:2000,
            dots:false
    };
    

    //logos = shuffle(logos);
    const div_logos = logos.map(logo => {
        return(
          <Col  xs={{span:10}} className="img_patrocindaor">
            <Link onClick={()=> eventHandler(logo.nombre)} to={logo.url}><img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'100%'}}  /></Link>
          </Col>
        )
    });

    return (
      <>
      <Row justify='center' align='middle'>
        <Col span={24}>
        <br /><br /><br /><br />
        </Col>
      {div_logos}
      </Row>
      </>
      
    );

};

export default ExpoMovil;